import { DAMAGE_CLASS_COLOR_MAP, primary } from '@/theme/colors';

const { DC0, DC1, DC2, DC3, DC4, DC5 } = DAMAGE_CLASS_COLOR_MAP;

export enum AlarmLevels {
  CLEAN = 'Clean',
  ALARM_1 = 'Alarm level 1',
  ALARM_2 = 'Alarm level 2',
  ALARM_3 = 'Alarm level 3',
  ALARM_1_OR_2 = 'Alarm level 1 or 2',
}

export enum DefectLevels {
  DC_0 = 'DC0',
  DC_1 = 'DC1',
  DC_2 = 'DC2',
  DC_3 = 'DC3',
  DC_4 = 'DC4',
  DC_5 = 'DC5',
}

export type Recommendations = AlarmLevels | DefectLevels;

type CriticalityLevelMapperProps = {
  [key in AlarmLevels | DefectLevels]: {
    alarmLevel: string;
    color: string;
  };
};

export const CriticalityLevelMapper: CriticalityLevelMapperProps = {
  [AlarmLevels.CLEAN]: { alarmLevel: 'Clean', color: primary.LightGrey },
  [AlarmLevels.ALARM_1]: { alarmLevel: '1', color: 'success.main' },
  [AlarmLevels.ALARM_1_OR_2]: { alarmLevel: '1/2', color: 'info.main' },
  [AlarmLevels.ALARM_2]: { alarmLevel: '2', color: 'warning.main' },
  [AlarmLevels.ALARM_3]: { alarmLevel: '3', color: 'error.main' },
  [DefectLevels.DC_0]: { alarmLevel: '0', color: DC0 },
  [DefectLevels.DC_1]: { alarmLevel: '1', color: DC1 },
  [DefectLevels.DC_2]: { alarmLevel: '2', color: DC2 },
  [DefectLevels.DC_3]: { alarmLevel: '3', color: DC3 },
  [DefectLevels.DC_4]: { alarmLevel: '4', color: DC4 },
  [DefectLevels.DC_5]: { alarmLevel: '5', color: DC5 },
};
