import { SxProps } from '@mui/material';
import {
  AutoCompleteField,
  DropDownField,
  RadioButtonField,
  CustomTextField,
  DatePickerField,
} from './FieldTemplates';
import { TemplateFieldTypeEnum } from '@/types';
import { DisplayConfig, InputAdormentIconTypeEnum, Maybe } from '@/__generated__/graphql';
import { AutoPopulatedFields } from '../AutoPopulatedFields';
import { ProposedPaintArea, ProposedPaintDiameter, ProposedPaintLength } from '../CalculatedFields';
import OpenDialogButton from '@/components/Analysis/RiskAssessment/shared/OpenDialogButton';

type Props = {
  fieldId?: string;
  fieldType?: string;
  fieldName?: string;
  fieldOptions?: string[];
  initialValue?: string;
  displayConfig?: Maybe<DisplayConfig>;
  inputAdormantHandlerMap?: {
    [InputAdormentIconTypeEnum.MeasurementTool]?: () => void;
    [InputAdormentIconTypeEnum.Reload]?: () => void;
  };
  onChange: (value: string) => void;
  sx?: SxProps;
  commentIdentifier?: string;
  disabled?: boolean;
  mode?: 'Edit' | 'ReadOnly';
  placeholder?: string;
};

export const CustomField = ({
  fieldId,
  fieldType,
  fieldName,
  fieldOptions,
  initialValue,
  displayConfig,
  onChange,
  inputAdormantHandlerMap,
  sx,
  commentIdentifier,
  disabled = false,
  mode = 'Edit',
  placeholder,
}: Props) => {
  const renderField = () => {
    switch (fieldType) {
      case TemplateFieldTypeEnum.SingleSelect:
        return fieldOptions && fieldOptions?.length < 11 ? (
          <DropDownField
            id={fieldId}
            title={fieldName}
            options={fieldOptions}
            value={initialValue}
            onFieldValueChange={onChange}
            mode={mode}
            sx={sx}
            disabled={disabled}
            valueFormat={displayConfig?.textFormat}
          />
        ) : (
          <AutoCompleteField
            id={fieldId}
            title={fieldName}
            options={fieldOptions}
            initValue={initialValue}
            onAutoCompleteFieldChange={onChange}
            disabled={disabled}
          />
        );

      case TemplateFieldTypeEnum.Textarea:
      case TemplateFieldTypeEnum.Input:
        return (
          <CustomTextField
            mode={mode}
            id={fieldId}
            sx={sx}
            multiline={fieldType === TemplateFieldTypeEnum.Textarea}
            title={fieldName}
            displayConfig={displayConfig}
            value={initialValue}
            onFieldValueChange={onChange}
            inputAdormantHandlerMap={inputAdormantHandlerMap}
            disabled={disabled}
            placeholder={placeholder}
          />
        );

      case TemplateFieldTypeEnum.Numeric:
        return (
          <CustomTextField
            mode={mode}
            numeric
            id={fieldId}
            sx={sx}
            title={fieldName}
            displayConfig={displayConfig}
            value={initialValue}
            onFieldValueChange={onChange}
            inputAdormantHandlerMap={inputAdormantHandlerMap}
            disabled={disabled}
          />
        );

      case TemplateFieldTypeEnum.RadioButton:
        return (
          <RadioButtonField
            mode={mode}
            id={fieldId}
            sx={sx}
            title={fieldName}
            options={fieldOptions}
            value={initialValue}
            onFieldValueChange={onChange}
            disabled={disabled}
          />
        );

      case TemplateFieldTypeEnum.Date:
        return (
          <DatePickerField
            format="dd MMM yyyy"
            mode={mode}
            id={fieldId}
            title={fieldName}
            value={''}
            sx={{ ...sx, mt: -1 }}
            onFieldValueChange={onChange}
            disabled={disabled}
          />
        );

      case TemplateFieldTypeEnum.AutoAssemblyService:
      case TemplateFieldTypeEnum.AutoAssemblyPressureService:
        return (
          <AutoPopulatedFields type={fieldType} title={fieldName} onTextAreaChange={onChange} />
        );

      case TemplateFieldTypeEnum.MeasurementPaintLength:
        return (
          <ProposedPaintLength
            title={fieldName}
            displayConfig={displayConfig}
            onFieldValueChange={onChange}
            inputAdormantHandlerMap={inputAdormantHandlerMap}
          />
        );

      case TemplateFieldTypeEnum.MeasurementPaintDiameter:
        return (
          <ProposedPaintDiameter
            title={fieldName}
            displayConfig={displayConfig}
            onFieldValueChange={onChange}
          />
        );

      case TemplateFieldTypeEnum.MeasurementPaintArea:
        return (
          <ProposedPaintArea
            title={fieldName}
            displayConfig={displayConfig}
            onFieldValueChange={onChange}
          />
        );

      case 'autocomplete': // this is not an instance of TemplateFieldTypeEnum
        return (
          <AutoCompleteField
            id={fieldId}
            options={fieldOptions}
            onAutoCompleteFieldChange={onChange}
            disabled={disabled}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {renderField()}
      {commentIdentifier && (
        <OpenDialogButton title={fieldName || 'N/A'} identifer={commentIdentifier} />
      )}
    </>
  );
};
