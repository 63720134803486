import { isNumber } from '@/utils';
import { useMemo, useState } from 'react';

type UsePaginationConfig = {
  initialRowsPerPage?: number;
};

const DEFAULT_ROWS_PER_PAGE = 10;

const getSanitizedRowsPerPage = (rowsPerPage: unknown) => {
  if (typeof rowsPerPage !== 'number') {
    return DEFAULT_ROWS_PER_PAGE;
  }

  if (Number.isNaN(rowsPerPage)) {
    return DEFAULT_ROWS_PER_PAGE;
  }

  if (rowsPerPage < 1) {
    return DEFAULT_ROWS_PER_PAGE;
  }

  return rowsPerPage;
};

export function usePaginationState<ListItemType>(
  list: Array<ListItemType>,
  config?: UsePaginationConfig
) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(() =>
    getSanitizedRowsPerPage(config?.initialRowsPerPage)
  );

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (changedRowsPerPage: number) => {
    if (!isNumber(changedRowsPerPage)) return;
    setRowsPerPage(changedRowsPerPage as number);
    setPage(0);
  };

  const paginatedList = useMemo(
    () => list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [list, page, rowsPerPage]
  );

  return {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    paginatedList,
  };
}
