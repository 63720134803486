import { MouseEvent } from 'react';
import { Tooltip } from '@mui/material';
import { WidgetIconButton } from './styles';
import { WidgetData } from '@/types';
import { useAnalysisCuboidActions } from '@/components/Analysis/modules/cuboids/state/actions';

type Props = {
  onChange: (event: MouseEvent) => void;
  selectedButtonId: string | undefined;
  data: WidgetData[];
};

// TODO: Replace checking template names with checking template types
// as soon as I figure out when 'type' cannot be queried on a poi template
const VOI_TEMPLATE_NAMES = new Set(['Paint Recommendation', 'No Scan Coverage', 'Recommendation']);

export const Widgets = ({ onChange, data, selectedButtonId }: Props) => {
  const { startAddingCuboid } = useAnalysisCuboidActions();

  return (
    <>
      {data.map((current) => {
        const clickHandler = (event: MouseEvent) => {
          onChange(event);
          // If the template is a VOI template, trigger the cuboid editing
          if (VOI_TEMPLATE_NAMES.has(current.displayName)) {
            startAddingCuboid();
          }
        };

        return (
          <Tooltip title={current.displayName} key={current.id} placement="left">
            <WidgetIconButton
              disableRipple
              disableFocusRipple
              name={current.displayName}
              id={current.id}
              size={current.size}
              color={current.color}
              isSelected={selectedButtonId === current.id}
              onClick={clickHandler}
            >
              {current.icon}
            </WidgetIconButton>
          </Tooltip>
        );
      })}
    </>
  );
};
