const ALLOWED_EXTENSIONS = ['png', 'jpeg', 'pdf', 'mp4', 'jpg'] as const;
const MAX_FILENAME_LENGTH = 60;
const MAX_FILE_SIZES: Record<(typeof ALLOWED_EXTENSIONS)[number], number> = {
  png: 10 * 1024 * 1024,
  jpeg: 10 * 1024 * 1024,
  jpg: 10 * 1024 * 1024,
  pdf: 80 * 1024 * 1024,
  mp4: 2 * 1024 * 1024 * 1024,
};

interface ValidationResult {
  validFiles: File[];
  fileErrors: string[];
}
const validateFiles = (files: File[]): ValidationResult => {
  const fileErrors: string[] = [];
  const validFiles: File[] = [];

  files.forEach((file) => {
    const currentFileErrors: string[] = [];

    // Check filename length
    if (file.name.length > MAX_FILENAME_LENGTH) {
      currentFileErrors.push(
        `Filename "${file.name}" is too long. Maximum length is ${MAX_FILENAME_LENGTH} characters.`
      );
    }

    // Check file extension
    const extension = file.name.split('.').pop()?.toLowerCase();
    if (
      !extension ||
      !ALLOWED_EXTENSIONS.includes(extension as (typeof ALLOWED_EXTENSIONS)[number])
    ) {
      currentFileErrors.push(
        `File extension for "${file.name}" not allowed. Allowed extensions are: ${ALLOWED_EXTENSIONS.join(', ')}.`
      );
    }

    // Check file size
    const maxFileSize = MAX_FILE_SIZES[extension as (typeof ALLOWED_EXTENSIONS)[number]] || 0;
    if (file.size > maxFileSize) {
      currentFileErrors.push(
        `File size of "${file.name}" exceeds the limit for ${extension} files. Maximum size is ${maxFileSize / (1024 * 1024)} MB.`
      );
    }

    if (currentFileErrors.length > 0) {
      fileErrors.push(...currentFileErrors);
    } else {
      validFiles.push(file);
    }
  });

  return { validFiles, fileErrors };
};

export default validateFiles;
