import { Checkbox, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useCallback, useMemo } from 'react';
import * as state from '@/components/Analysis/state';
import { NONSELECTED_DEFECT_COLOR } from '@/constants';
import { convertRgbaToColorHexString } from '@/utils';

export const NonSelectedToggle = () => {
  const [isEnabled, setIsEnabled] = useRecoilState(state.nonSelectedDefectsIsEnabled);

  const handleCheckboxChange = useCallback(() => {
    setIsEnabled((current) => !current);
  }, [setIsEnabled]);

  const colorBoxStyles = useMemo(
    () => ({
      backgroundColor: convertRgbaToColorHexString(NONSELECTED_DEFECT_COLOR),
      height: '2rem',
      width: '2rem',
      borderRadius: '10px',
      border: '1px solid black',
    }),
    []
  );

  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" className="defect-toggle-container">
        <Checkbox checked={isEnabled} onChange={handleCheckboxChange} className="defect-toggle" />
        <div>Non-selected</div>
      </Stack>
      <div style={colorBoxStyles} />
    </Stack>
  );
};
