import React from 'react';
import { FileUploadAndViewer } from '../../../MediaUploadsAndViewer/FileUploadAndViewer';
import { DetailsAccordion } from '../StyledComponents';
import { File } from '@/__generated__/graphql';

type Props = { referenceId: string; collectionName: string; files: File[] };

export const MediaUploads = ({ referenceId, collectionName, files }: Props) => {
  return (
    <DetailsAccordion title="Attachments">
      <FileUploadAndViewer
        referenceId={referenceId}
        collectionName={collectionName}
        files={files}
      />
    </DetailsAccordion>
  );
};
