import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Skeleton,
  Button,
  Typography,
  Stack,
  Box,
} from '@mui/material';
import {
  TemplateTypeEnum,
  useGetAssemblyPointOfInterestsQuery,
  useStructureTemplateByTypeQuery,
} from '@/__generated__/graphql';
import { localiseBlisterHeight } from '@/utils/unitSystem';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as state from '@/components/Analysis/state';
import { CardContainer } from '@/components/Analysis/Viewer/Panels/AssetDetails/styles';
import { useCallback, useMemo } from 'react';
import { BlisterToAdd, Permissions, PointOfInterestDocument } from '@/types';
import { abyssColors, primary, SC_CATEGORY_COLORS } from '@/theme/colors';
import { useHavePermission } from '@/hooks';
import { generatePoiDisplayName } from '@/components/Analysis/modules/pointOfInterest/PointOfInterestTab/SelectedPoiContent/utils';
import { useImageFromLocationId } from '@/components/Analysis/Viewer/hooks/useImageFromLocationId';
import * as poiState from '@/components/Analysis/modules/pointOfInterest/state';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { BLISTER_TYPE, PIT_TYPE } from '@/constants';
import { nominateButtonStyles, cellColorIndicatorStyles, tableCellStyle } from '../styles';
import { containedButtonStyle } from '@/theme';
import { scrollDesign } from '@/components/Analysis/Insights/InsightsExplorer/styles';

const { primary: primaryColors, secondary: secondaryColors } = abyssColors;
const { darkBlue } = primary;

type Props = {
  assemblyId: string | undefined;
  onNominateBlisterClicked: (blister: PointOfInterestDocument) => void;
  governingBlisterId: string | undefined;
  setBlistersCount: React.Dispatch<React.SetStateAction<number>>;
};

export const BlistersTable = ({
  assemblyId,
  onNominateBlisterClicked,
  governingBlisterId,
  setBlistersCount,
}: Props) => {
  const unitSystem = useRecoilValue(state.unitSystem);
  const userCanUpdateAsset = useHavePermission(Permissions.UPDATE_ASSET);
  const allViewpoints = useRecoilValue(state.structureLocations);
  const setCameraTarget = useSetRecoilState(state.cameraTarget);
  const setSelectedSpherical = useSetRecoilState(state.selectedSpherical);
  const [blisterToAdd, setBlisterToAdd] = useRecoilState(poiState.blisterToAdd);
  const structureId = useGetStructureId();
  const { data: structureTemplate } = useStructureTemplateByTypeQuery({
    variables: {
      structureId,
      type: TemplateTypeEnum.Assembly,
    },
  });

  const assemblyTemplate = useMemo(() => {
    return structureTemplate?.allTemplates || [];
  }, [structureTemplate?.allTemplates]);

  const isBpStructure = assemblyTemplate[0]?.fields?.some(
    (field) => field.key === 'governing_sc_category_bp'
  );

  const { data, loading: isLoadingPOI } = useGetAssemblyPointOfInterestsQuery({
    variables: { assemblyIds: [assemblyId || ''] },
    skip: !assemblyId,
  });

  const blisterPois = useMemo(() => {
    const allPois = data?.allPointOfInterests;
    if (!allPois) return [];
    const filteredPois = allPois.filter((poi) => [BLISTER_TYPE, PIT_TYPE].includes(poi.type));
    setBlistersCount(filteredPois.length);
    return filteredPois;
  }, [data?.allPointOfInterests, setBlistersCount]);

  const getSphericalFromId = useImageFromLocationId(allViewpoints);

  const handleOnBlisterRowSelected = useCallback(
    (poi: PointOfInterestDocument) => {
      const { blister, location, centerPoint3d } = poi;
      if (
        blister &&
        location?.id &&
        blister.centerPoint &&
        // if POI has no 3D coordinates, dont set the camera target
        // this is in case POI was uploaded via CSV
        centerPoint3d
      ) {
        const selectedSpherical = getSphericalFromId(location?.id);

        if (selectedSpherical) {
          setCameraTarget({
            position: selectedSpherical.position,
            lookAt: [centerPoint3d.x, centerPoint3d.y, centerPoint3d.z],
          });

          setSelectedSpherical({ ...selectedSpherical, lookAt: centerPoint3d });
        }

        if (blister.radiusPoint && blister.centerPoint) {
          const updatedBlisterToAdd: BlisterToAdd = {
            ...blisterToAdd,
            state: 'Loading',
            centerPoint: {
              x: blister.centerPoint.bearing,
              y: blister.centerPoint.elevation,
            },
            edgePoint: {
              x: blister.radiusPoint.bearing,
              y: blister.radiusPoint.elevation,
            },
          };
          setBlisterToAdd(updatedBlisterToAdd);
        }
      }
    },
    [getSphericalFromId, blisterToAdd, setBlisterToAdd, setSelectedSpherical, setCameraTarget]
  );

  const handleNominateClicked = useCallback(
    (pointOfInterest: PointOfInterestDocument, isNominated: Boolean) => {
      if (isNominated) return;
      if (pointOfInterest?.blister) {
        onNominateBlisterClicked(pointOfInterest);
      }
    },
    [onNominateBlisterClicked]
  );

  const columns = [
    { id: 'id', label: 'ID', width: 100 },
    { id: 'scCategory', label: 'SC CATEGORY', width: 80, visibility: isBpStructure },
    { id: 'wallLossOrHeight', label: isBpStructure ? 'HEIGHT' : 'WALL LOSS', width: 80 },
    { id: 'governing', label: 'GOVERNING', width: 100 },
  ];

  return (
    <Paper elevation={0} sx={{ padding: 1, width: '100%' }}>
      <TableContainer sx={{ width: '100%', ...scrollDesign, overflowX: 'auto' }}>
        {isLoadingPOI ? (
          <CardContainer>
            <Skeleton variant="rectangular" width="100%" height={300} />
          </CardContainer>
        ) : blisterPois.length > 0 ? (
          <Table sx={{ borderCollapse: 'collapse', minWidth: isBpStructure ? 400 : 200, mb: 1 }}>
            <TableHead>
              <TableRow>
                {columns
                  .filter((column) => column.visibility === undefined || column.visibility)
                  .map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      sx={{ ...tableCellStyle, width: column.width, border: 0 }}
                    >
                      <Typography sx={{ fontSize: 10, fontWeight: 700 }}>{column.label}</Typography>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {blisterPois.map((poi, rowIndex) => {
                const displayWallLossOrHeight = isBpStructure
                  ? localiseBlisterHeight(unitSystem, poi.blister?.maxHeight || 0)
                  : localiseBlisterHeight(unitSystem, poi.blister?.wallLoss?.value || 0);

                const isNominated = poi.id === governingBlisterId;
                const poiDisplayName = generatePoiDisplayName({
                  name: poi?.name,
                  poiId: poi?.poiId,
                  poiType: poi?.type,
                });

                const buttonStyles = {
                  color: isNominated ? secondaryColors[800] : primaryColors[50],
                  backgroundColor: isNominated ? secondaryColors[50] : darkBlue,
                };

                const scCategory = poi?.blister?.scCategory;

                return (
                  <TableRow key={rowIndex}>
                    <TableCell
                      align="center"
                      sx={{
                        ...tableCellStyle,
                        width: columns[0].width,
                        cursor: 'pointer',
                        ':hover': { backgroundColor: abyssColors.primary[50] },
                      }}
                      onClick={() => handleOnBlisterRowSelected(poi as PointOfInterestDocument)}
                    >
                      <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
                        {poiDisplayName}
                      </Typography>
                    </TableCell>
                    {isBpStructure && (
                      <TableCell align="center" sx={{ ...tableCellStyle, width: columns[1].width }}>
                        <Stack direction="row">
                          {scCategory && SC_CATEGORY_COLORS[scCategory] && (
                            <Box
                              sx={{
                                ...cellColorIndicatorStyles,
                                backgroundColor: SC_CATEGORY_COLORS[scCategory],
                              }}
                            />
                          )}
                          <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
                            {scCategory || 'N/A'}
                          </Typography>
                        </Stack>
                      </TableCell>
                    )}
                    <TableCell align="center" sx={{ ...tableCellStyle, width: columns[2].width }}>
                      <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
                        {displayWallLossOrHeight || 'N/A'}
                      </Typography>
                    </TableCell>
                    {userCanUpdateAsset && (
                      <TableCell
                        align="center"
                        sx={{ ...tableCellStyle, textAlign: 'center', width: columns[2].width }}
                      >
                        <Button
                          data-testid={`nominate-${poi?.name}`}
                          onClick={() =>
                            handleNominateClicked(poi as PointOfInterestDocument, isNominated)
                          }
                          sx={{
                            ...containedButtonStyle,
                            ...nominateButtonStyles,
                            ...buttonStyles,
                            cursor: isNominated ? 'default' : 'pointer',
                            ':hover': { ...buttonStyles },
                          }}
                        >
                          {isNominated ? 'Nominated' : 'Nominate'}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
            No blisters found in this assembly
          </Typography>
        )}
      </TableContainer>
    </Paper>
  );
};
