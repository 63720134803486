import React, { useMemo } from 'react';
import { Box, TextField } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { METERS_TO_FEET_FACTOR } from '@abyss/3d-viewer';
import { useMeasurementTool } from '@/components/Analysis/modules/measurementTool';
import { InputAdormentIconTypeEnum, DisplayConfig, Maybe } from '@/__generated__/graphql';
import { useInputAdornment } from '@/hooks';
import * as state from '@/components/Analysis/state';
import { UnitSystemEnum } from '@/__generated__/graphql';
import { unitSystem as unitSystemState } from '@/components/Analysis/state';
import queryString from 'query-string';
import { convertFeetToMetersDisplay, convertMetresToFeetDisplay } from '@/utils/unitSystem';

type Props = {
  title?: string;
  displayConfig?: Maybe<DisplayConfig>;
  onFieldValueChange?: (value: string) => void;
  inputAdormantHandlerMap?: {
    [InputAdormentIconTypeEnum.MeasurementTool]?: () => void;
    [InputAdormentIconTypeEnum.Reload]?: () => void;
    ''?: () => void;
  };
  value?: string;
};

const emptyHandler = () => {};

export const EditableProposedPaintLength = ({
  rows,
  RenderInputAdormant,
  onFieldValueChange,
  title,
  value,
}: {
  rows: number;
  RenderInputAdormant: JSX.Element;
  onFieldValueChange?: (value: string) => void;
  title: string | undefined;
  value?: string;
}) => {
  const unitSystem = useRecoilValue(unitSystemState);

  const paintLength =
    unitSystem === UnitSystemEnum.Imperial
      ? convertMetresToFeetDisplay(Number(value), false)
      : value;

  return (
    <TextField
      id={`TextArea-${title}`}
      label={`${title} (${unitSystem === UnitSystemEnum.Imperial ? 'ft' : 'm'})`}
      multiline={false}
      rows={rows}
      defaultValue={paintLength || 0}
      onChange={(event) =>
        onFieldValueChange &&
        onFieldValueChange(
          unitSystem === UnitSystemEnum.Imperial
            ? convertFeetToMetersDisplay(Number(event.target.value), false) || ''
            : event.target.value
        )
      }
      InputProps={{
        endAdornment: RenderInputAdormant,
      }}
    />
  );
};

export const AutoGeneratedPaintLength = ({
  rows,
  RenderInputAdormant,
  onFieldValueChange,
  title,
}: {
  rows: number;
  RenderInputAdormant: JSX.Element;
  onFieldValueChange?: (value: string) => void;
  title: string | undefined;
}) => {
  const { measurementDistance } = useMeasurementTool();
  const unitSystem = useRecoilValue(unitSystemState);

  const computedPaintLengthValue = useMemo(() => {
    const proposedLength =
      unitSystem === UnitSystemEnum.Imperial
        ? Number(measurementDistance) * METERS_TO_FEET_FACTOR
        : measurementDistance;

    if (onFieldValueChange !== undefined) {
      onFieldValueChange(String(measurementDistance));
      return proposedLength;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurementDistance, unitSystem]);

  return (
    <TextField
      id={`TextArea-${title}`}
      label={`${title} (${unitSystem === UnitSystemEnum.Imperial ? 'ft' : 'm'})`}
      multiline={false}
      rows={rows}
      value={computedPaintLengthValue}
      onChange={emptyHandler}
      InputProps={{
        endAdornment: RenderInputAdormant,
      }}
    />
  );
};

export const ProposedPaintLength = ({
  title,
  displayConfig,
  onFieldValueChange,
  inputAdormantHandlerMap,
  value,
}: Props) => {
  const parsed = queryString.parse(window.location.search, { arrayFormat: 'comma' });
  const insightsTab = parsed.tab;

  const rows = displayConfig?.textAreaRows || 4;
  const inputAdorment = displayConfig?.inputAdorment || undefined;
  const iconType = displayConfig?.inputAdorment?.iconType || '';

  const selectedSpherical = useRecoilValue(state.selectedSpherical);

  const RenderInputAdormant: JSX.Element = useInputAdornment({
    inputAdorment,
    iconType,
    inputAdormantHandlerMap,
    displayConfig,
    selectedSpherical,
  });

  return (
    <>
      <Box
        sx={{
          width: '100%',
          '& .MuiTextField-root': { width: '100%' },
        }}
        component="form"
        noValidate
        autoComplete="off"
      >
        <div>
          {insightsTab ? (
            <EditableProposedPaintLength
              rows={rows}
              RenderInputAdormant={RenderInputAdormant}
              onFieldValueChange={onFieldValueChange}
              title={title}
              value={value}
            />
          ) : (
            <AutoGeneratedPaintLength
              rows={rows}
              RenderInputAdormant={RenderInputAdormant}
              onFieldValueChange={onFieldValueChange}
              title={title}
            />
          )}
        </div>
      </Box>
    </>
  );
};
