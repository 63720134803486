import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { downloadFile, urlToBase64 } from '@/utils';
import { File, FileTypes, FileUploadStatus, useDeleteFilesMutation } from '@/__generated__/graphql';
import { Attachment } from './type';
import { MediaViewer } from '@/components/shared/MediaViewer';
import { FileDisplay } from './FileDisplay';
import { getCloudfrontUrl } from '@/utils/cloudfront';

type Props = {
  referenceId: string;
  collectionName: string;
  files: File[];
  attachments: Attachment[];
  setAttachments: React.Dispatch<React.SetStateAction<Attachment[]>>;
  enableUpload?: boolean;
  onUploadComplete?: (index: number, file: Attachment) => void;
  showRemoveIcon?: boolean;
  setIsUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
  handleRemoveAttachment?: (index: number) => void;
};

export const ListFiles = ({
  referenceId,
  collectionName,
  files,
  attachments,
  setAttachments,
  setIsUpdating,
}: Props) => {
  const [deleteFiles] = useDeleteFilesMutation();
  useEffect(() => {
    (async function getImageURLs() {
      if (files && files.length > 0) {
        const fetchedFiles = await Promise.all(
          files
            .filter(
              (file) =>
                file.uploadStatus &&
                [FileUploadStatus.Success, FileUploadStatus.Inprogress].includes(file.uploadStatus)
            )
            .map(async ({ fileName, resourcePath, type, id, uploadStatus }) => {
              const fileUrl = resourcePath ? getCloudfrontUrl(resourcePath) : '';
              const isImage =
                type.includes('image/') ||
                [FileTypes.Jpeg, FileTypes.Png].includes(type as FileTypes);
              const base64 = isImage ? await urlToBase64(fileUrl) : undefined;
              return {
                id: id || '',
                name: fileName,
                base64: base64,
                type,
                isNew: false,
                url: fileUrl,
                uploadStatus: uploadStatus || FileUploadStatus.Success,
              };
            })
        );
        setAttachments(() => fetchedFiles);
      }
    })();
  }, [files, setAttachments]);

  const onUploadComplete = (index: number, file: Attachment) => {
    if (file.isNew && file.resourcePath) {
      const poiAttachmentUrl = getCloudfrontUrl(file.resourcePath);
      setAttachments((previous) => {
        const newAttachments = [...previous];
        newAttachments[index] = {
          ...newAttachments[index],
          url: poiAttachmentUrl,
          isNew: false,
          uploadStatus: FileUploadStatus.Success,
        };
        return newAttachments;
      });
      if (setIsUpdating) setIsUpdating(false);
    }
  };

  const handleDownloadFile = (attachmentIndex: number) => {
    const currentAttachment = attachments[attachmentIndex];
    if (!currentAttachment.url) return;
    downloadFile(currentAttachment.url, currentAttachment.name);
  };

  const handleRemoveFile = (fileIndex: number) => {
    const currentFile = files[fileIndex];
    if (!currentFile.id) return;
    deleteFiles({
      variables: {
        input: {
          referenceId,
          collectionName,
          fileIds: [currentFile.id],
        },
      },
    });
    setAttachments(attachments.filter((file) => file.id !== currentFile.id));
  };
  return (
    <Grid container spacing={{ xl: 3, md: 2 }} px={2}>
      <MediaViewer
        mediaFiles={attachments}
        handleDownloadAttachment={handleDownloadFile}
        handleRemoveAttachment={handleRemoveFile}
      >
        {attachments.map((file, index) => (
          <Grid item lg={4} sm={6} key={file.id || index}>
            <FileDisplay
              referenceId={referenceId}
              collectionName={collectionName}
              selectedAttachment={file}
              selectedAttachmentIndex={index}
              showRemoveIcon={false}
              enableUpload={true}
              onUploadComplete={onUploadComplete}
            />
          </Grid>
        ))}
      </MediaViewer>
    </Grid>
  );
};
