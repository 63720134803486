import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import { Alert } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { snackbarMessage } from '@/state';

import { PartsLabelReportButton } from './PartsLabelReportButton';
import { useFeatureFlag } from '@/hooks';
import { LineFixingMenu } from './LineFixing';

type Props = {
  onResetCameraClicked?: () => void;
  updateCameraTarget: () => void;
  getHtmlCanvas?: () => HTMLCanvasElement | undefined;
};

const buttonStyles = {
  marginLeft: 8,
};

const SuccessAlert = (
  <div>
    <Alert severity="success">Url copies to clipboard.</Alert>
  </div>
);

export const MenuBar = ({ onResetCameraClicked, getHtmlCanvas, updateCameraTarget }: Props) => {
  const setSnackbarMessage = useSetRecoilState(snackbarMessage);

  const isBetaUser = useFeatureFlag('beta-user');
  const isTaskLead = useFeatureFlag('ait-task-lead');

  const handleGenerateDeepLinkClicked = useCallback(() => {
    updateCameraTarget();
    navigator.clipboard.writeText(window.location.href);
    setSnackbarMessage({
      shouldShow: true,
      content: SuccessAlert,
    });
  }, [setSnackbarMessage, updateCameraTarget]);

  const handleResetCameraClicked = useCallback(() => {
    if (onResetCameraClicked) {
      onResetCameraClicked();
    }
  }, [onResetCameraClicked]);

  const handleTake3dSnapshot = useCallback(() => {
    const filename = 'viewer_screenshot';

    const htmlCanvas = getHtmlCanvas && getHtmlCanvas();

    if (htmlCanvas === undefined) {
      return;
    }
    const canvas2 = document.createElement('canvas');
    canvas2.width = htmlCanvas.width;
    canvas2.height = htmlCanvas.height;
    const context2 = canvas2.getContext('2d');
    // eslint-disable-next-line eqeqeq
    if (context2 == undefined) {
      return;
    }

    // fill background
    context2.fillStyle = 'black';
    context2.fillRect(0, 0, canvas2.width, canvas2.height);
    context2.drawImage(htmlCanvas, 0, 0);

    const imgData = canvas2.toDataURL();
    const a = document.createElement('a');
    a.href = imgData!;
    a.download = filename;
    a.click();
  }, [getHtmlCanvas]);

  return (
    <div style={{ position: 'absolute', margin: '8px', zIndex: 2 }}>
      <Button variant="contained" size="medium" color="primary" onClick={handleResetCameraClicked}>
        Reset Camera View
      </Button>
      <Button
        variant="contained"
        size="medium"
        color="primary"
        onClick={handleTake3dSnapshot}
        style={buttonStyles}
      >
        Take 3d-View Snapshot
      </Button>
      <Button
        variant="contained"
        size="medium"
        color="primary"
        onClick={handleGenerateDeepLinkClicked}
        style={buttonStyles}
      >
        Copy view link
      </Button>
      {isTaskLead && (
        <LineFixingMenu
          sx={{
            marginLeft: 1,
          }}
        />
      )}
      {isBetaUser && <PartsLabelReportButton />}
    </div>
  );
};
