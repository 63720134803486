import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as state from '@/state';
import { useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as localState from './state';
import { LINE_FIXING_CSV_HEADERS } from '@/constants';
import { downloadLineFixingTableAsCsv } from './utils';
import { modalPaperStyles } from './styles';
import { usePaginationState } from '@/hooks/usePaginationState';

export const FixableTagsTable = () => {
  const allAssemblies = useRecoilValue(state.allAssemblies);
  // Any assembly that has an original tag name was updated via the line fixing feature
  const userCreatedAssembliesWithNoSuperCategory = useMemo(() => {
    return allAssemblies
      .filter((assembly) => assembly.isNewlyAdded && !assembly.superCategory)
      .map((item) => ({
        [LINE_FIXING_CSV_HEADERS[0]]: item.tagName,
        [LINE_FIXING_CSV_HEADERS[1]]: item.originalTagname,
        [LINE_FIXING_CSV_HEADERS[2]]: item.superCategory,
      }));
  }, [allAssemblies]);

  const {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    paginatedList: paginatedAssemblies,
  } = usePaginationState(userCreatedAssembliesWithNoSuperCategory);

  const [lineFixingState, setLineFixingState] = useRecoilState(localState.lineFixingState);

  const handleClose = () => {
    setLineFixingState({
      ...lineFixingState,
      state: 'closed',
    });
  };

  return (
    <Modal open={lineFixingState.state === 'fixable-tags-table-modal-open'} onClose={handleClose}>
      <Paper sx={modalPaperStyles}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" fontSize={20} fontWeight={600}>
            Newly Added tags
          </Typography>
          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography variant="body1" fontSize={16} mt={2} mb={3}>
          Super categories (Missing or the relevant super category added)
        </Typography>
        <Box sx={{ tableLayout: 'fixed', height: '70%', overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                {LINE_FIXING_CSV_HEADERS.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedAssemblies.map((assembly) => (
                <TableRow key={assembly['ACTUAL TAGS']}>
                  {LINE_FIXING_CSV_HEADERS.map((header) => (
                    <TableCell key={header}>{assembly[header]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <TablePagination
          component="div"
          count={userCreatedAssembliesWithNoSuperCategory.length}
          page={page}
          onPageChange={(_, newPage) => handleChangePage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => handleChangeRowsPerPage(Number.parseInt(event.target.value))}
          rowsPerPageOptions={[5, 10, 25]}
          sx={{ py: 1 }}
        />

        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Done
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              downloadLineFixingTableAsCsv(userCreatedAssembliesWithNoSuperCategory);
            }}
          >
            Download CSV
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};
