import { ApolloCache, DefaultContext, FetchResult, MutationFunctionOptions } from '@apollo/client';
import { BulkLineFixingInput, BulkLineFixingMutation, Exact } from '../PartsLabelReportButton';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { snackbarMessage } from '@/state';
import { CSV_VALIDATION_ERROR_CODE, SUPPORT_ERROR_MESSAGE } from '@/constants';
import { GraphQLError } from 'graphql';
import { useSetRecoilState } from 'recoil';
import * as state from './state';

type Options = {
  mutate: (
    options?:
      | MutationFunctionOptions<
          BulkLineFixingMutation,
          Exact<{
            input: BulkLineFixingInput;
          }>,
          DefaultContext,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ApolloCache<any>
        >
      | undefined
  ) => Promise<FetchResult<BulkLineFixingMutation>>;

  handleCloseMenu: () => void;
  structureId: string;
};

export const useGetLineFixingFileChangeHandler = ({
  handleCloseMenu,
  mutate,
  structureId,
}: Options) => {
  const { showSnackBar } = useSnackBarMessage({ atom: snackbarMessage });
  const setLineFixingState = useSetRecoilState(state.lineFixingState);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const fileData = reader.result;
      mutate({
        variables: {
          input: {
            csv: fileData as string,
            structureId,
          },
        },
      })
        .then((data) => {
          const updateCount = data.data?.bulkLineFixing?.updatedCount;

          if (!updateCount || updateCount === 0) {
            showSnackBar('No lines were fixed. Please check the file and try again.', 'error');
            return;
          }

          showSnackBar(
            `${updateCount} line(s) have been fixed. Please refresh the page to see the changes.`,
            'success'
          );
        })
        .catch((error_) => {
          const gqlError: GraphQLError | undefined = error_?.graphQLErrors?.[0];

          if (!gqlError) {
            showSnackBar(SUPPORT_ERROR_MESSAGE, 'error');
            handleCloseMenu();
            return;
          }

          if (gqlError.extensions?.code === CSV_VALIDATION_ERROR_CODE) {
            setLineFixingState((previous) => ({
              ...previous,
              inputCsv: fileData as string,
              error: gqlError,
              state:
                gqlError.extensions?.type === 'validation'
                  ? 'validation-error'
                  : 'generic-csv-error',
            }));
            return;
          }

          showSnackBar(error_.message || SUPPORT_ERROR_MESSAGE, 'error');
          handleCloseMenu();
        });
    });
    reader.readAsText(file);
  };

  return onFileChange;
};
