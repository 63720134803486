import { Chip, Grid, Typography, ButtonBase as RippleEffect } from '@mui/material';
import React from 'react';
import { ApolloError } from '@apollo/client';
import { useRecoilValue } from 'recoil';
import { LinearProgress } from '../shared/LinearProgress';
import { PlatformLoadingSkeleton } from './Platform/PlatformLoadingSkeleton';
import { PlatformCard } from './Platform';
import { CardInsightTypeEnum, PlatformsType } from '@/types';
import { StructureDeploymentLevel, StructureStatus, UnitSystemEnum } from '@/__generated__/graphql';
import { convertMetresSqToFeetSqDisplay } from '@/utils/unitSystem';
import { unitSystem as unitSystemState } from '@/components/Analysis/state';
import { PlatformCardBoxOverlay } from './styles';

type PlatformProps = {
  loading: boolean;
  selectedPlatforms: PlatformsType;
  error: ApolloError | undefined;
  handlePlatformChange: (
    id: string,
    status: StructureStatus,
    deploymentLevel?: StructureDeploymentLevel | null
  ) => void;
};

type CardInsights = PlatformsType[0]['cardInsights'];
type CardInsight = NonNullable<NonNullable<CardInsights>[0]>;

const getVisibleCardInsights = (cardInsights: CardInsights) => {
  return (cardInsights ?? []).filter(
    (item): item is CardInsight => item !== null && item?.visible === true
  );
};

export const GridView = ({
  loading,
  selectedPlatforms,
  error,
  handlePlatformChange,
}: PlatformProps) => {
  const unitSystem = useRecoilValue(unitSystemState);
  return (
    <Grid sx={{ my: 2 }} justifyContent="center" container spacing={3.5} data-testid="platforms">
      {loading && <PlatformLoadingSkeleton />}
      {((!loading && selectedPlatforms.length === 0) || error) && (
        <Typography variant="body1" fontSize="2.6rem" my={10} fontWeight={600}>
          No platforms found
        </Typography>
      )}
      {!loading &&
        selectedPlatforms.length > 0 &&
        selectedPlatforms.map(
          ({ name, status, coverImagePath, description, id, deploymentLevel, cardInsights }) => {
            const isComponentLevel = deploymentLevel === StructureDeploymentLevel.ComponentLevel;
            return (
              <Grid item lg={3} md={3} sm={6} xs={12} key={id}>
                <RippleEffect
                  component="div"
                  onClick={() => handlePlatformChange(id, status, deploymentLevel)}
                  sx={{ borderRadius: '10px', display: 'block' }}
                >
                  <PlatformCard name={name} imageUrl={coverImagePath} subHeading={description}>
                    {getVisibleCardInsights(cardInsights).map(({ type, color, value }) => (
                      <>
                        {(type === CardInsightTypeEnum.CIRCULAR_PROGRESS ||
                          type === CardInsightTypeEnum.LINEAR_PROGRESS) && (
                          <LinearProgress
                            data-testid={`linear-progress-${id}`}
                            color={color ?? '#FF9395'}
                            label={name ?? 'N/A'}
                            percentage={Number(value) || 0}
                            type={type}
                          />
                        )}
                        {type === CardInsightTypeEnum.SIMPLE_TEXT && (
                          <PlatformCardBoxOverlay>
                            <Typography variant="body2" fontSize="small">
                              {name}
                            </Typography>
                            <Typography variant="body2" fontWeight="bold">
                              {value}
                            </Typography>
                          </PlatformCardBoxOverlay>
                        )}
                        {type === CardInsightTypeEnum.UNIT_AREA && (
                          <PlatformCardBoxOverlay>
                            <Typography variant="body2" fontSize="small">
                              {name}
                            </Typography>
                            <Typography variant="body2" fontWeight="bold">
                              {unitSystem === UnitSystemEnum.Imperial && value
                                ? `${convertMetresSqToFeetSqDisplay(Number(value))} sq ft`
                                : value
                                  ? `${value} sq m`
                                  : 'N/A'}
                            </Typography>
                          </PlatformCardBoxOverlay>
                        )}
                      </>
                    ))}
                    {deploymentLevel && (
                      <Chip
                        label={deploymentLevel?.replace(/_/g, ' ') ?? 'N/A'}
                        sx={{
                          background: `${isComponentLevel ? '#C1F4E8' : '#E0E5FF'}`,
                          color: `${isComponentLevel ? '#09695B' : '#4030A3'}`,
                          fontWeight: 500,
                          height: '23px',
                          width: 'fit-content',
                          mt: '17px',
                          fontSize: '1.2rem',
                        }}
                      />
                    )}
                  </PlatformCard>
                </RippleEffect>
              </Grid>
            );
          }
        )}
    </Grid>
  );
};
