import { FormLabel, Slider } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import * as globalState from '@/state';
import { useCallback, useEffect, useState } from 'react';
import { ToggleItemVisibility } from '@/components/Analysis/Viewer/Panels/Visibility/ToggleItemVisibility';
import { DYNAMIC_POINT_SIZE_SCALE } from '@/constants';
const valuetext = (value: number) => {
  return `${value}`;
};

// dynamic pointSize == 1 corresponds to 10mm
// minimal pow(1.5, -5) == 0.13 ==> 1.3mm
// maximal pow(1.5, 7) == 17.08 ==> 171mm
const SLIDER_VALUE_OFFSET = -5;
const SLIDER_MIN_VALUE = 0;
const SLIDER_MAX_VALUE = 12;
const SLIDER_DEFAULT_VALUE = 0 - SLIDER_VALUE_OFFSET;

export const DynamicPointSize = () => {
  const [enableDynamicPointSize, setEnableDynamicPointSize] = useRecoilState<boolean>(
    globalState.enableDynamicPointSize
  );
  const setDynamicPointSize = useSetRecoilState<number>(globalState.dynamicPointSize);

  const [unfinishedValue, setUnfinishedValue] = useState<number>(SLIDER_DEFAULT_VALUE);

  const toggleDynamicPointSize = useCallback(() => {
    setEnableDynamicPointSize((enableDynamicPoints) => {
      return !enableDynamicPoints;
    });
  }, [setEnableDynamicPointSize]);

  useEffect(() => {
    setDynamicPointSize(Math.pow(DYNAMIC_POINT_SIZE_SCALE, unfinishedValue + SLIDER_VALUE_OFFSET));
  }, [setDynamicPointSize, unfinishedValue]);

  const handlePointSizeChanged = (event: Event, value: number | number[]) => {
    setUnfinishedValue(value as number);
  };

  return (
    <>
      <ToggleItemVisibility
        label="Dynamic Point Sizing"
        isVisible={enableDynamicPointSize}
        toggleVisibility={toggleDynamicPointSize}
      />
      {enableDynamicPointSize && (
        <>
          <FormLabel>Dynamic Point Size</FormLabel>
          <Slider
            min={SLIDER_MIN_VALUE}
            max={SLIDER_MAX_VALUE}
            step={1}
            aria-label="DynamicPointSize"
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            value={unfinishedValue}
            onChange={handlePointSizeChanged}
            size="small"
          />
        </>
      )}
    </>
  );
};
