import { useCallback, useMemo } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import { SphericalImageProps, SvgMarkerInstanceProps, SvgMarkerSetProps } from '@abyss/3d-viewer';
import * as analysisState from '@/components/Analysis/state';
import * as poiState from '../state';
import {
  pointOfInterestIcon,
  pointOfInterestHoveredIcon,
} from '@/components/shared/PointOfInterestIcon';
import { Point3d, PointOfInterestQuery } from '@/types';
import { uncertainBlisterColor } from '@/theme/colors';
import { upperSnakeCase } from '@/utils';
import { BLISTER_TYPE, VOI_POI_TYPES } from '@/constants';

const SCALE_ADJUSTMENT_FACTOR = 0.05;

const point3dToArray = (point: Point3d | null | undefined) => {
  if (!point) return undefined;
  return [point?.x, point?.y, point?.z] as [x: number, y: number, z: number];
};

export function usePointOfInterestMarkers(selectedSpherical?: SphericalImageProps) {
  const selectedAssemblyId = useRecoilValue(analysisState.selectedAssemblyId);
  const poiFilteredByCheckbox = useRecoilValue(poiState.poiFilteredByCheckbox);
  const [selectedPoiId, setSelectedPoiId] = useRecoilState(poiState.selectedPointOfInterestId);
  const [blisterToAdd, setBlisterToAdd] = useRecoilState(poiState.blisterToAdd);
  const editPointOfInterest = useRecoilValue(poiState.editPointOfInterest);
  const arePoisVisible = useRecoilValue(analysisState.arePoisVisible);
  const poiTemplateIcons = useRecoilValue(analysisState.poiTemplateIcons);
  const nominatedBlister = useRecoilValue(analysisState.governingValueByAssemblyId);

  const allGoverningPois = Object.values(nominatedBlister)
    .map((value) => value.pointOfInterest?.id || undefined)
    .filter(Boolean) as string[];

  const scale = useMemo(() => {
    return SCALE_ADJUSTMENT_FACTOR * (selectedSpherical ? 0.3 : 0.5);
  }, [selectedSpherical]);

  const handleMarkerClick = useCallback(
    (poi: PointOfInterestQuery[0]) => {
      setSelectedPoiId(poi.id);
      setBlisterToAdd((current) => ({
        ...current,
        state: 'Reviewing',
      }));
    },
    [setBlisterToAdd, setSelectedPoiId]
  );

  const poiToExclude = useMemo(() => {
    if (blisterToAdd?.state !== 'UpdatingBlister' && !editPointOfInterest.isAutoGenerated)
      return undefined;
    return editPointOfInterest.pointOfInterest?.id;
  }, [blisterToAdd, editPointOfInterest]);

  const filteredPois = useMemo(() => {
    if (!arePoisVisible) return [];

    const filteredPoisWithoutVois = poiFilteredByCheckbox.filter((poi) => {
      return !VOI_POI_TYPES.includes(poi.type);
    });

    if (selectedAssemblyId) {
      return filteredPoisWithoutVois.filter(
        (poi) => poi.assembly.id === selectedAssemblyId && poi.id !== poiToExclude
      );
    }
    return poiToExclude
      ? filteredPoisWithoutVois.filter((poi) => poi.id !== poiToExclude)
      : filteredPoisWithoutVois;
  }, [poiFilteredByCheckbox, arePoisVisible, selectedAssemblyId, poiToExclude]);

  const poiMarkerSets: SvgMarkerSetProps[] = useMemo(() => {
    const poisByType = new Map<
      string,
      {
        type: string;
        isUncertain: boolean;
        isGoverning: boolean;
        isSelected: boolean;
        pois: PointOfInterestQuery;
      }
    >();
    filteredPois.forEach((poi) => {
      const isUncertain = poi.type === BLISTER_TYPE && !poi?.blister?.maxHeight;
      const isGoverning = allGoverningPois.includes(poi.id);
      const isSelected = selectedPoiId === poi.id;
      const key = `${poi.type}-${isUncertain}-${isGoverning}-${isSelected}`;
      let entry = poisByType.get(key);
      if (!entry) {
        entry = { type: poi.type, isUncertain, isGoverning, isSelected, pois: [] };
        poisByType.set(key, entry);
      }
      poisByType.set(key, {
        ...entry,
        pois: [...entry.pois, poi],
      });
    });
    console.log(poisByType);
    const markerSets: SvgMarkerSetProps[] = [];
    poisByType.forEach(({ type, isUncertain, isGoverning, isSelected, pois }, index) => {
      const poiIcon =
        poiTemplateIcons.find((poiTemplate) => poiTemplate.name === upperSnakeCase(type))?.icon ??
        undefined;
      const poiIconColor =
        poiTemplateIcons.find((poiTemplate) => poiTemplate.name === upperSnakeCase(type))?.color ??
        undefined;

      const markerSet: SvgMarkerSetProps = {
        id: `poi-markers-${type.toLowerCase()}-${index}`,
        normalSvg: {
          url: pointOfInterestIcon({
            type,
            poiIcon,
            iconColor: poiIconColor,
            isUncertain,
            isGoverning,
            isSelected,
            ...(isUncertain ? { overrideColor: uncertainBlisterColor } : {}),
          }),
          renderOrder: 2,
          zStep: 0.1,
          renderOrderStep: 0,
          depthWrite: true,
        },
        hoveredSvg: {
          url: pointOfInterestHoveredIcon({
            type: type,
            poiIcon,
            iconColor: poiIconColor,
            isGoverning,
            isSelected,
            ...(isUncertain ? { overrideColor: uncertainBlisterColor } : {}),
          }),
          renderOrder: 2,
          zStep: 0.1,
          renderOrderStep: 0,
          depthWrite: true,
        },

        instances: pois
          .map((poi) => ({
            id: poi.id,
            position: point3dToArray(poi.centerPoint3d),
            normalScale: scale,
            hoveredScale: scale,
            onClick: () => handleMarkerClick(poi),
          }))
          // trying to convince TypeScript that the instances array is of type SvgMarkerInstanceProps[]
          // even though the filter is removing undefined values
          // This ensures that it does not try to render markers for POIs that have no 3D coordinates
          // i.e were uploaded via CSV
          .filter((instance) => instance.position !== undefined) as SvgMarkerInstanceProps[],
      };

      markerSets.push(markerSet);
    });
    return markerSets;
  }, [filteredPois, poiTemplateIcons, selectedPoiId, scale, handleMarkerClick, allGoverningPois]);

  return poiMarkerSets;
}
