import { GraphQLError } from 'graphql';
import { atom } from 'recoil';

type LineFixingStateType = {
  error: GraphQLError | undefined;
  menuAnchorElement: HTMLElement | undefined;
  state:
    | 'closed'
    | 'confirm-revert'
    | 'table-modal-open'
    | 'fixable-tags-table-modal-open'
    | 'validation-error'
    | 'generic-csv-error'
  inputCsv: string | undefined;
};

export const lineFixingState = atom<LineFixingStateType>({
  key: 'lineFixingState',
  default: {
    error: undefined,
    menuAnchorElement: undefined,
    state: 'closed',
    inputCsv: undefined,
  },
});
