import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import DownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Stack, Typography } from '@mui/material';
import { abyssColors, primary } from '@/theme/colors';

type Props = {
  title: string;
  children: React.ReactNode;
  subTitle?: string | number;
};

export const DetailsAccordion = ({ children, title, subTitle }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Box sx={{ mt: '12px !important', mb: 1.5 }}>
      <Accordion
        disableGutters
        elevation={0}
        expanded={isExpanded}
        onChange={(_, state) => setIsExpanded(state)}
        sx={{ backgroundColor: abyssColors.primary[50] }}
      >
        <AccordionSummary
          sx={{
            p: 0,
            minHeight: 0,
            width: 'calc(100% + 5px)',
            '& .MuiAccordionSummary-content': { margin: 0 },
            background: abyssColors.primary[50],
          }}
          expandIcon={<DownIcon sx={{ color: primary.darkBlue, mt: '-3px' }} />}
        >
          <Stack
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Typography fontSize="12px" fontWeight={500} color="info.main">
              {title}
            </Typography>
            {subTitle && (
              <Typography fontSize="12px" fontWeight={600} sx={{ color: primary.darkBlue }}>
                {subTitle}
              </Typography>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ mt: 1 }}>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};
