import {
  Box,
  Button,
  ListItemButton,
  TableCell,
  Tabs,
  Typography,
  styled as muiStyled,
  styled,
} from '@mui/material';
import { abyssColors, userManagementTableBorderColor } from '../../theme/colors';

export const tablePaginationStyles = {
  border: 'none',
};

export const TablePaginationContainer = muiStyled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const ScrollableList = muiStyled(Box)(() => ({
  overflowY: 'auto',
  borderColor: 'divider',
  height: '100%',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
  background: 'white',
  position: 'relative',
}));

export const TabsContainer = muiStyled(Box)(() => ({
  marginTop: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '44px',
  paddingRight: '40px',
  alignItems: 'center',
}));

export const dialogOverlay = {
  top: '15% !important',
  left: '35% !important',
  transform: 'none',
  width: 536,
};

export const inviteScrollDesign = {
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '10px',
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    background: '#888',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
};

export const usersLengthStyle = {
  p: 0.5,
  color: '#1a2c41',
  fontWeight: 'bold',
  background: '#e9eaec',
  borderRadius: '50%',
  width: '25px',
  height: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const accordionStyles = {
  root: {
    boxShadow: 'none',
    '&.MuiAccordion-root::before': {
      display: 'none',
    },
  },
  summary: {
    p: 0,
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
};
export const chipStyles = {
  padding: '4px 12px',
  background: 'var(--primary-primary-100, #E7EEF6)',
  color: 'var(--primary)',
};

interface ButtonProps {
  contained?: boolean;
  hideBorder?: boolean;
}

export const SmallButton = muiStyled(Button)(
  ({ contained = false, hideBorder = false }: ButtonProps) => ({
    padding: '4px 10px',
    height: '32px',
    borderRadius: '4px',
    fontSize: '1.3rem',
    background: contained ? abyssColors.primary[500] : abyssColors.neutral[50],
    color: contained ? abyssColors.neutral[50] : abyssColors.primary[500],
    fontWeight: 500,
    lineHeight: 'normal',
    boxShadow: 'none',
    textTransform: 'uppercase',
    ...(contained || hideBorder ? {} : { border: `1px solid ${abyssColors.primary[500]}` }),
    '&:hover': {
      backgroundColor: contained ? abyssColors.primary[600] : abyssColors.neutral[100],
      transition: 'background-color 0.3s',
    },
    '& .MuiButton-icon ': {
      fontSize: '1.6rem',
      paddingLeft: '4px',
    },
    '&:disabled': {
      '&:disabled': {
        background: contained ? abyssColors.neutral[300] : abyssColors.neutral[50],
        color: contained ? abyssColors.neutral[50] : abyssColors.primary[300],
      },
    },
  })
);

export const ProfileButton = muiStyled(SmallButton)(() => ({
  padding: '0.4rem 0.8rem',
  height: '2.6rem',
  width: 'fit-content',
  fontSize: '1.2rem',
  textTransform: 'none',
  fontWeight: 600,
  lineHeight: 'normal',
  '& span': {
    padding: '0 0.6rem',
  },
}));

export const BackButton = muiStyled(SmallButton)(() => ({
  padding: '0.8rem 0.8rem',
  width: 'fit-content',
  fontSize: '1.6rem',
  textTransform: 'none',
  fontWeight: 600,
  lineHeight: 'normal',
  gap: '2.4rem',
  border: '0px !important',
  '& span': {
    lineHeight: 'normal',
  },
}));

export const ManagementTabs = muiStyled(Tabs)(() => ({
  width: '100%',
  '& .MuiTabs-flexContainer': {
    gap: '16px',
  },
  '& .MuiTab-root': {
    padding: '12px 15px',
    fontSize: '1.6rem',
    fontStyle: 'normal',
    letterSpacing: '0',
    minWidth: 'fit-content',
    minHeight: 'fit-content',
    textTransform: 'none',
    fontWeight: 400,
  },
  '& .Mui-selected': {
    color: `${abyssColors.primary[700]} !important`,
    fontWeight: 600,
  },
  '& .MuiTab-textColorPrimary': {
    color: abyssColors.primary[400],
  },
  '& .MuiTabs-indicator': {
    backgroundColor: abyssColors.primary[700],
    height: '4px',
    // borderRadius: '4px',
  },
}));

export const FloatingLine = muiStyled(Box)(() => ({
  borderTop: '1px solid #D9D9D9',
  width: '100%',
  position: 'relative',
  bottom: '0px',
  zIndex: '-1',
}));

export const Container = muiStyled(Box)(() => ({
  position: 'relative',
  width: '100%',
  overflowY: 'hidden',
}));

export const ListButton = muiStyled(ListItemButton)(() => ({
  padding: '16px',
  display: 'flex',
  minHeight: 0,
  height: 'fit-content',
  justifyContent: 'space-between',
  fontSize: '1.6rem',
  maxHeight: '48px',
  borderRadius: '4px',
}));

export const listButtonStyles = {
  padding: '16px',
  display: 'flex',
  minHeight: 0,
  height: 'fit-content',
  justifyContent: 'space-between',
  fontSize: '1.6rem',
  maxHeight: '48px',
  borderRadius: '4px',
  '& .MuiTypography-root': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80%',
  },
};

export const ActionButton = muiStyled(Button)(() => ({
  fontSize: '1.5rem',
  lineHeight: 'normal',
  height: 'fit-content',
  color: abyssColors.primary[500],
}));

export const ListBox = muiStyled(Box)(() => ({
  position: 'relative',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
  borderRight: '1px solid #D9D9D9',
}));

export const PanelBox = muiStyled(Box)(() => ({
  overflowY: 'auto',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
  padding: '48px',
}));

export const PanelTitle = muiStyled(Typography)(() => ({
  fontSize: '2.8rem',
  color: abyssColors.primary[500],
  fontWeight: 700,
  lineHeight: 'normal',
}));

export const listStyles = {
  '& .Mui-selected': {
    backgroundColor: abyssColors.primary[100],
  },
  padding: 0,
};

export const StyledTableCell = styled(TableCell)(() => ({
  color: abyssColors.primary[500],
  border: `1px solid ${userManagementTableBorderColor} !important`,
  padding: '8px 16px',
  flex: 1,
  fontSize: '1.4rem',
  height: 57,
}));
