import { Box, LinearProgress, Stack, keyframes } from '@mui/material';

const flashAnimation = keyframes`
  0% { opacity: 0.334; }
  50% { opacity: 1; }
  100% { opacity: 0.334; }
`;
type Props = {
  background?: 'dark' | 'light';
  hideProgress?: boolean;
  backdrop?: boolean;
  flashing?: boolean;
  variant?: 'sm' | 'lg';
};

export const PageLoader = ({
  background = 'light',
  hideProgress = false,
  backdrop = false,
  flashing = false,
  variant = 'lg',
}: Props) => {
  return (
    <Stack
      direction="column"
      sx={{
        height: variant === 'lg' ? '100%' : 'auto',
        width: variant === 'lg' ? '100%' : 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        '& img':
          variant === 'lg' ? { maxWidth: { xl: '6.7rem', md: '4.8rem' } } : { maxWidth: '4.8rem' },
        ...(backdrop
          ? {
              backgroundColor:
                background === 'dark' ? 'rgba(0, 0, 0, 0.8)' : ' rgba(255,255,255, 0.8)',
            }
          : {}),
      }}
    >
      <Box sx={{ animation: flashing ? `${flashAnimation} 2s infinite` : 'none' }}>
        <img
          src={
            background === 'dark'
              ? '/assets/abyss_fabric_mini_logo_white.svg'
              : '/assets/abyss_fabric_mini_logo_black.svg'
          }
          alt="Abyss Fabric"
          style={{
            height: '6.4rem',
            ...(hideProgress ? {} : { marginBottom: variant === 'lg' ? '30px' : '1.6rem' }),
          }}
        />
      </Box>
      {!hideProgress && (
        <LinearProgress
          sx={{
            width: '20rem',
            '& .MuiLinearProgress-colorPrimary': {
              backgroundColor: background === 'light' ? '#9E9E9E' : '#C5C5C5',
            },
            '& .MuiLinearProgress-barColorPrimary': {
              backgroundColor: background === 'light' ? '#000' : '#fff',
            },
          }}
        />
      )}
    </Stack>
  );
};
