type IconProps = {
  style?: React.CSSProperties;
};

export const ArrowUpIcon = ({ style = {} }: IconProps) => {
  return (
    <img
      src="/assets/icons/arrow-up-icon.svg"
      alt="Arrow Up"
      style={{ height: '1.6rem', ...style }}
    />
  );
};
