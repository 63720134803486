import { CircularProgress, Stack } from '@mui/material';
import { useState } from 'react';
import { useHavePermission } from '@/hooks';
import { ArrowUpIcon } from '@/assets/icons/ArrowUpIcon';
import UploadFileDialog from './UploadFileDialog';
import { Permissions } from '@/types';
import { Attachment } from './type';
import {
  MediaButton,
  MediaButtonLabel,
} from '../../modules/pointOfInterest/PointOfInterestTab/SelectedPoiContent/Tabs/styles';
import { ListFiles } from './ListFiles';
import { File } from '@/__generated__/graphql';

type Props = {
  referenceId: string;
  collectionName: string;
  files: File[];
};

export const FileUploadAndViewer = ({ files, referenceId, collectionName }: Props) => {
  const [openFileDialog, setOpenFileDialog] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const dialogTitle = 'Attachments';
  const buttonText = 'Add';

  const handleDialogToggle = () => setOpenFileDialog(!openFileDialog);
  const userCanUploadMedia = useHavePermission(Permissions.CREATE_UPLOAD_MEDIA);

  return (
    <>
      <Stack direction="row" alignItems="start" gap={1.5} marginY={2}>
        {userCanUploadMedia && (
          <MediaButton disabled={isUpdating} onClick={handleDialogToggle} variant="outlined">
            {isUpdating ? <CircularProgress size={12} /> : <ArrowUpIcon />}
            <MediaButtonLabel>{isUpdating ? 'Uploading' : 'Upload'}</MediaButtonLabel>
          </MediaButton>
        )}
      </Stack>
      <ListFiles
        attachments={attachments}
        setAttachments={setAttachments}
        referenceId={referenceId}
        collectionName={collectionName}
        files={files}
        setIsUpdating={setIsUpdating}
      />
      <UploadFileDialog
        openDialog={openFileDialog}
        setOpenDialog={setOpenFileDialog}
        referenceId={referenceId}
        collectionName={collectionName}
        attachments={attachments}
        dialogTitle={dialogTitle}
        buttonText={buttonText}
        setAttachments={setAttachments}
        setIsUpdating={setIsUpdating}
      />
    </>
  );
};
