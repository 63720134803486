import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  AbyssViewerContextProvider,
  ColorMap,
  Number3,
  PointCloudMaterialProps,
  PointPickResult,
} from '@abyss/3d-viewer';
import { createElement, useCallback, useMemo } from 'react';
import Alert from '@mui/material/Alert';
import { AbyssViewer } from './AbyssViewer';
import { Container } from './styles';
import { useColors } from './colors';
import * as state from '@/state';
import { CuboidClickMode, PartId } from '@/types';

import { useNormalEffects } from './ViewerModes/Normal';
import { useImageViewpointEffects } from './ViewerModes/ImageViewpoint';
import { useIsolatePartEffects, handlers as isolatePartHandlers } from './ViewerModes/IsolatePart';
import { getCloudfrontUrl } from '@/utils/cloudfront';
import { TestIds } from '../../../../../../cypress/testIds';

import { SceneCuboids } from '../SplitCuboids/SceneCuboids';
import { useCreateCuboid } from '../SplitCuboids/hooks';
import { useTaggingKeyboardShortcuts } from '@/hooks';
import { useImageSets } from './useImageSets';

export type ZoneBoxProps = {
  box: { min: [x: number, y: number, z: number]; max: [x: number, y: number, z: number] };
  color?: number;
  label: string;
};

export const Inspection3dViewer = () => {
  const recoilInspectionMetadata = useRecoilValue(state.inspectionMetadata);

  const token = useRecoilValue(state.auth0TokenState);
  const structureRelationships = useRecoilValue(state.structureRelationships);

  const enableDynamicPointSize = useRecoilValue(state.enableDynamicPointSize);
  const dynamicPointSize = useRecoilValue(state.dynamicPointSize);
  const pointSize = useRecoilValue(state.pointSize);

  const structureId = useRecoilValue(state.selectedStructureId);
  const segmentsByCurrentZones = useRecoilValue(state.getSegmentsByCurrentZones);

  const currentCuboid = useRecoilValue(state.currentCuboid);
  const cuboidClickMode = useRecoilValue(state.cuboidClickMode);

  useTaggingKeyboardShortcuts();
  // Temporary workaround for slow loading of bin and image files in Pakistan
  // 1. Get the point cloud and image files
  //     - Download horn-mountain-pd.zip from https://drive.google.com/drive/folders/13hdwkiJqbS0prPZi0WtW21av0UcHTm7h?usp=sharing
  //     - Extract to a folder e.g. C:/ - you should see 2 folders inside "horn-mountain-pd" - "images" with jpg files and "octree" with bin files
  //     - Rename the containing folder to 6298b4cf34349ba5acb519d4
  // 2. Host these files on a server within your own machine
  //     - Download and install LTS version of npm releavant to your OS https://nodejs.org/en/download/
  //     - From a command line run: npm install --global http-server
  //     - From a command line, navigate to the folder horn-mountain-pd
  //     - From within horn-mountain-pd in command line run: http-server --cors='*'
  //     - You should see some output saying Available on: http://127.0.0.1:8080
  // 3. Enable Fabric V2 to load files from your own machine
  //     - Go to Fabric V2 temporary version here, stay on Platforms list page for now - https://d2gplkryt7cldz.cloudfront.net/
  //     - Open Chrome dev tools (usually Ctrl+Shift+i)
  //     - Open Console tab (usually second from the left after Elements)
  //     - Run the following in Console tab (paste this in then hit Enter): localStorage.setItem("files-base-url", "http://localhost:8080")
  //     - Select Horn Mountain PD platform - this should now load point cloud and image files from your local machine

  // To use a http server instead of local machine
  // 1. Set up file/folder structure on http server as follows
  // /6298b4cf34349ba5acb519d4 - horn mountain PD
  //    /images - should contain all the .jpg files for horn mountain PD sphericals
  //    /octree - should contain octree_hierarch.json and .bin files for horn mountain PD
  // /61e7ca258f1a3cc4411029cc - marlin UD - lower priority, once horn mountain (folder above) is working end to end
  //    /images - should contain all the .jpg files for marlin UD sphericals
  //    /octree - should contain octree_hierarch.json and .bin files for marlin UD

  // 2. Enable Fabric V2 to load files from your own machine - this only works for Horn Mountain PD
  //     - Go to Fabric V2 temporary version here and log in, stay on Platforms list page for now - https://d2gplkryt7cldz.cloudfront.net/
  //     - Open Chrome dev tools (usually Ctrl+Shift+i)
  //     - Open Console tab (usually second from the left after Elements)
  //     - Run the following in Console tab and replace http://localhost:8080 with your http server url (paste this in then hit Enter): localStorage.setItem("files-base-url", "http://localhost:8080")
  //     - Select any platform - this should now load point cloud and image files from your local machine

  const octreePointCloudsJsonPath = useMemo(() => {
    const filesBaseUrl = localStorage.getItem('files-base-url');
    return [
      {
        path: filesBaseUrl
          ? `${filesBaseUrl}/${structureId}/octree/octree_hierarchy.json`
          : getCloudfrontUrl(recoilInspectionMetadata?.octreeResourcesPath as string),
        contents: [{ 'part-id': true }, { 'corrosion-id': true }, { 'rusting-id': true }],
      },
    ];
  }, [structureId, recoilInspectionMetadata]);

  const { sphericalImages, rectilinearImages } = useImageSets(structureId!);

  const {
    toggleAnnotationSelection: isolatePartToggleSelection,
    toggleMultiAnnotationSelection: isolatePartToggleMultiSelection,
  } = isolatePartHandlers.useToggleAnnotationSelection();

  const toggleAnnotationSelection = useRecoilCallback(
    ({ snapshot, set }) =>
      async (
        annotation3dReference: number,
        isMultiSelectKeyDown: boolean,
        isDeselectKeyDown: boolean
      ) => {
        const structureRelationshipsValue = await snapshot.getPromise(state.structureRelationships);
        const abyssViewerMode = (await snapshot.getPromise(state.abyssViewerState)).mode;
        const annotationId =
          structureRelationshipsValue?.byAnnotation3dReference.get(annotation3dReference);
        if (annotationId === undefined) {
          return;
        }

        // Handle selection when in IsolatePart mode - ie can select multiple annotations
        if (abyssViewerMode === 'IsolatePart') {
          isolatePartToggleSelection(annotationId, isMultiSelectKeyDown, isDeselectKeyDown);
          return;
        }

        /**
         * Since a part has many annotations, we need to check if the clicked annotation
         * belongs to the same part as the current selected annotation before
         * deselecting/selecting the part.
         */
        const partId = structureRelationshipsValue?.byAnnotationId?.get(annotationId)?.partId;
        if (typeof partId !== 'string') return;

        const currentPartIds = await snapshot.getPromise(state.selectedPartIds);
        let newPartIds: PartId[];

        if (isMultiSelectKeyDown) {
          newPartIds = currentPartIds.includes(partId)
            ? currentPartIds
            : [...currentPartIds, partId];
        } else if (isDeselectKeyDown) {
          newPartIds = currentPartIds.filter((aid) => aid !== partId);
        } else if (partId === currentPartIds[0]) {
          newPartIds = [];
        } else {
          newPartIds = [partId];
        }
        set(state.selectedPartIds, newPartIds);
        // eslint-disable-next-line unicorn/no-null
        set(state.searchedHighlightedTag, null);
      },
    [isolatePartToggleSelection]
  );

  const { allColors, colorsForAssembly } = useColors(structureRelationships?.byAssemblyId);

  // This sets the initial color map for points WITHOUT actually setting the colors themselves.
  // Color setting is handled by the various effects per viewer mode (see /ViewerModes/*/effects.ts)
  //
  // Doing it this way is a performance optimisation
  // - changing pointCloudMaterials is expensive,
  // - changing colors by using an existing pointCloudMaterial's colorMap.set is much faster
  const pointCloudMaterials = useMemo(() => {
    const maxId = 1024 * 1024 - 1; // should be same as `maxId` of `annotation` field in octree dataformat
    const colorMap = new ColorMap(maxId, undefined, allColors.noAssemblyColour);

    return new Map<string, PointCloudMaterialProps>([
      [
        'annotation',
        {
          colorMap,
          attributeName: 'annotation',
          pointSize: enableDynamicPointSize ? dynamicPointSize : pointSize,
          enableSizeAttenuation: enableDynamicPointSize,
        },
      ],
    ]);
  }, [allColors.noAssemblyColour, enableDynamicPointSize, dynamicPointSize, pointSize]);

  const colorMap: ColorMap | undefined = useMemo(() => {
    const colorMapCheck = pointCloudMaterials.get('annotation')?.colorMap;
    return colorMapCheck && 'setColor' in colorMapCheck ? colorMapCheck : undefined;
  }, [pointCloudMaterials]);

  const createCuboid = useCreateCuboid();

  const handlePointSelect = useCallback(
    (pickResult: PointPickResult) => {
      if (!currentCuboid && cuboidClickMode === CuboidClickMode.AddCuboid) {
        const position = pickResult
          .getPointAttributes(pickResult.pointIndex, ['position'])
          .get('position');
        if (position && Array.isArray(position) && position.length === 3) {
          createCuboid(position as Number3);
        }
        return;
      }
      if (currentCuboid || cuboidClickMode !== CuboidClickMode.Default) {
        return;
      }
      const annotation3dReference = pickResult
        .getPointAttributes(pickResult.pointIndex, ['annotation'])
        .get('annotation');
      if (annotation3dReference !== undefined && !Array.isArray(annotation3dReference)) {
        toggleAnnotationSelection(
          annotation3dReference,
          pickResult.mouseEvent.shiftKey || pickResult.mouseEvent.metaKey,
          pickResult.mouseEvent.altKey
        );
      }
    },
    [toggleAnnotationSelection, currentCuboid, cuboidClickMode, createCuboid]
  );

  const handleBoxSelect = useRecoilCallback(
    ({ snapshot, set }) =>
      async (pointPickResults: PointPickResult[]) => {
        const selectedZone = await snapshot.getPromise(state.selectedZone);
        const abyssViewerMode = (await snapshot.getPromise(state.abyssViewerState)).mode;
        const inSphericalView = await snapshot.getPromise(state.inSphericalView);
        const normalVisibilityRange = await snapshot.getPromise(state.normalVisibilityRange);
        if (
          (!selectedZone && inSphericalView) ||
          (selectedZone && inSphericalView && normalVisibilityRange === 'All')
        ) {
          set(state.snackbarMessage, {
            shouldShow: true,
            content: createElement(
              Alert,
              { severity: 'warning' },
              `Please select a zone and pick zone only or custom visibility range before
    using multi-select in viewpoint mode.`
            ),
          });
        }

        const annotation3dReferences = new Set<number>();
        const annotationIds: string[] = [];
        let isShiftKeyDown = false;
        const structureRelationshipsValue = await snapshot.getPromise(state.structureRelationships);
        if (!structureRelationshipsValue) return;

        // get list of mongo ids
        pointPickResults.forEach((pointPickResult) => {
          const { pointIndex, getPointAttributes, mouseEvent } = pointPickResult;
          const annotation3dReference = getPointAttributes(pointIndex, ['annotation']).get(
            'annotation'
          );
          if (
            annotation3dReference === undefined ||
            typeof annotation3dReference !== 'number' ||
            annotation3dReferences.has(annotation3dReference)
          )
            return;
          annotation3dReferences.add(annotation3dReference);

          if (mouseEvent.shiftKey) {
            isShiftKeyDown = true;
          }
          const annotationId =
            structureRelationshipsValue.byAnnotation3dReference.get(annotation3dReference);
          if (annotationId) {
            annotationIds.push(annotationId);
          }
        });

        if (abyssViewerMode === 'IsolatePart') {
          isolatePartToggleMultiSelection(annotationIds, isShiftKeyDown);
          return;
        }

        // select multiple parts using the select box drawn
        const newPartIds: string[] = [];
        annotationIds.forEach((annotationId) => {
          const partId = structureRelationshipsValue.byAnnotationId.get(annotationId)?.partId;
          if (partId) {
            newPartIds.push(partId);
          }
        });

        if (isShiftKeyDown) {
          // select additional parts and preserve existing
          set(state.selectedPartIds, (currentSelectedIds) => {
            return [...new Set([...currentSelectedIds, ...newPartIds])];
          });
        } else {
          // deselect multiple parts
          set(state.selectedPartIds, (currentSelectedIds) => {
            const newPartIdsSet = new Set(newPartIds);
            const newSelectedIds: string[] = [];
            currentSelectedIds.forEach((currentSelectedId) => {
              if (!newPartIdsSet.has(currentSelectedId)) {
                newSelectedIds.push(currentSelectedId);
              }
            });
            return newSelectedIds;
          });
        }
      },
    [isolatePartToggleMultiSelection]
  );

  useNormalEffects(colorMap, allColors, colorsForAssembly);
  useImageViewpointEffects(colorMap, allColors, colorsForAssembly);
  useIsolatePartEffects(colorMap, allColors);

  const selectionColors = useMemo(() => {
    return { shiftKeyColor: allColors.selectedColour, altKeyColor: 0xffffffff }; // TODO: retrieve alt from db too?
  }, [allColors.selectedColour]);

  return (
    <>
      {recoilInspectionMetadata && sphericalImages && token && (
        <Container data-test-id={TestIds.PLATFORM_EXPLORER_TAGGING}>
          <AbyssViewerContextProvider>
            <AbyssViewer
              octreePointCloudsJsonPath={octreePointCloudsJsonPath}
              onPointSelect={handlePointSelect}
              onPointCloudSelection={handleBoxSelect}
              pointCloudMaterials={pointCloudMaterials}
              sphericalImages={sphericalImages}
              rectilinearImages={rectilinearImages}
              selectionColors={selectionColors}
              segments={segmentsByCurrentZones}
            >
              <SceneCuboids />
            </AbyssViewer>
          </AbyssViewerContextProvider>
        </Container>
      )}
    </>
  );
};
