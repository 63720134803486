/* eslint-disable unicorn/no-null */
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  AssemblyGoverningValueDocument,
  useAssemblyGoverningValueLazyQuery,
  useDeletePointOfInterestMutation,
} from '@/__generated__/graphql';
import { ConfirmationDialog } from '@/components/shared/ConfirmationDialog';
import * as poiState from '@/components/Analysis/modules/pointOfInterest/state';
import * as state from '@/components/Analysis/state';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { BLISTER_TYPE, PIT_TYPE } from '@/constants';

type Props = {
  poiId: string;
};

export const SelectedPoiMenuButton = ({ poiId }: Props) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const setSelectedPointOfInterestId = useSetRecoilState(poiState.selectedPointOfInterestId);
  const setApplyFilters = useSetRecoilState(state.applyFilters);
  const { showSnackBar } = useSnackBarMessage({});
  const setAllPOI = useSetRecoilState(poiState.structurePois);
  const setGoverningValue = useSetRecoilState(state.governingValueByAssemblyId);

  const assemblyId = useRecoilValue(state.selectedAssemblyId);

  const open = useMemo(() => Boolean(anchorElement), [anchorElement]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const [deletePoi] = useDeletePointOfInterestMutation({
    variables: { input: { pointOfInterestId: poiId } },
    refetchQueries: [AssemblyGoverningValueDocument],
  });

  const [getGoverningValue] = useAssemblyGoverningValueLazyQuery({
    variables: { assemblyId },
  });

  const handleClose = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const handleDeleteClick = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data: poiData } = await deletePoi();
      setAllPOI((previous) => previous.filter((poi) => poi.id !== poiId));
      setApplyFilters(true);
      setSelectedPointOfInterestId(undefined);
      const poiType = poiData?.deletePointOfInterest?.type;
      if (!poiType || !assemblyId) return;
      const governingTypePois = [BLISTER_TYPE, PIT_TYPE, 'DEFECT_CLASS'];
      if (!governingTypePois.includes(poiType)) return;
      try {
        const { data } = await getGoverningValue();
        const governingId = data?.assembly?.recommendation?.governingValue?.pointOfInterest?.id;
        if (governingId) {
          setGoverningValue((previous) => ({
            ...previous,
            [assemblyId]: { pointOfInterest: { id: governingId } },
          }));
        }
      } catch {
        showSnackBar('Failed to update governing poi', 'error');
      }
    } catch {
      showSnackBar('Failed to delete POI. Please contact support', 'error');
    }
    showSnackBar('POI deleted successfully', 'success');
    setIsUpdateDialogOpen(false);
    setIsLoading(false);
    handleClose();
  }, [
    deletePoi,
    setAllPOI,
    setApplyFilters,
    showSnackBar,
    setSelectedPointOfInterestId,
    handleClose,
    poiId,
    assemblyId,
    getGoverningValue,
    setGoverningValue,
  ]);

  return (
    <div>
      <ConfirmationDialog
        loading={{
          state: isLoading,
          text: 'Deleting...',
        }}
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        description="Are you sure you want to delete this POI?"
        title="Delete POI"
        handleClose={() => {}}
        isOpen={isUpdateDialogOpen}
        onConfirm={handleDeleteClick}
        onCancel={() => setIsUpdateDialogOpen(false)}
      />
      <IconButton
        id="selected-poi-menu-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="basic-menu" anchorEl={anchorElement} open={open} onClose={handleClose}>
        <MenuItem disabled>
          <ShareIcon sx={{ mr: 1 }} />
          Share
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsUpdateDialogOpen(true);
          }}
        >
          <DeleteIcon sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};
