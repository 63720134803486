import { useCallback } from 'react';
import { ButtonProps } from '@mui/material/Button';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CircularProgress } from '@mui/material';
import {
  GetAssemblyTemplateFieldDataDocument,
  useNominateGoverningPointOfInterestMutation,
} from '@/__generated__/graphql';
import Button from '@/components/shared/Button';
import { GetAssembliesByTemplateFieldsFiltersForFilterSideBarDocument } from '@/__generated__/graphql';
import * as state from '@/components/Analysis/state';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { GetOneAssemblyForBlistersDocument } from '@/__generated__/graphql';

type Props = {
  blisterId: string;
  closeDialog: () => void;
  shouldOpenUpdateAlarmDialog?: boolean;
} & ButtonProps;

export const AcceptNominationButton = ({
  blisterId,
  closeDialog,
  shouldOpenUpdateAlarmDialog = false,
  ...props
}: Props) => {
  const setisCriticalityLevelDialogOpen = useSetRecoilState(state.isCriticalityLevelDialogOpen);
  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);
  const setNominatedBlister = useSetRecoilState(state.governingValueByAssemblyId);

  const { showSnackBar } = useSnackBarMessage({
    variant: 'filled',
  });
  const [manuallyNominateBlister, { loading }] = useNominateGoverningPointOfInterestMutation({
    variables: {
      input: {
        pointOfInterestId: blisterId,
        lastModifiedFor: 'Governing blister nomination',
      },
    },
    refetchQueries: [
      GetAssembliesByTemplateFieldsFiltersForFilterSideBarDocument,
      GetOneAssemblyForBlistersDocument,
      GetAssemblyTemplateFieldDataDocument,
    ],
  });

  const onButtonClick = useCallback(() => {
    manuallyNominateBlister()
      .then((response) => {
        const newGoverningBlisterId = response?.data?.nominateGoverningPointOfInterest;
        if (newGoverningBlisterId) {
          showSnackBar('Blister nominated successfully', 'success');
          if (shouldOpenUpdateAlarmDialog) {
            setisCriticalityLevelDialogOpen(true);
          }
          setNominatedBlister((current) => {
            const governingBlisterData = current[selectedAssemblyId!];

            return {
              ...current,
              [selectedAssemblyId!]: {
                ...governingBlisterData,
                pointOfInterest: {
                  id: blisterId,
                },
              },
            };
          });
          closeDialog();
        }
      })
      .catch(() => showSnackBar('Failed to nominate blister', 'error'));
  }, [
    closeDialog,
    manuallyNominateBlister,
    shouldOpenUpdateAlarmDialog,
    showSnackBar,
    setisCriticalityLevelDialogOpen,
    selectedAssemblyId,
    setNominatedBlister,
    blisterId,
  ]);

  return (
    <Button {...props} disabled={loading} onClick={onButtonClick}>
      {loading ? <CircularProgress /> : 'Accept'}
    </Button>
  );
};
