import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import * as state from '@/state';
import { PointBudgetSlider } from '../PointBudgetSlider';
import { ToggleItemVisibility } from '@/components/Analysis/Viewer/Panels/Visibility/ToggleItemVisibility';
import { useFeatureFlag } from '@/hooks';

export const PointBudgetControls = () => {
  const [enableAdaptivePointBudget, setEnableAdaptivePointBudget] = useRecoilState<boolean>(
    state.enableAdaptivePointBudget
  );

  const isAlphaUser = useFeatureFlag('alpha-user');

  const toggleAdaptivePointBudget = useCallback(() => {
    setEnableAdaptivePointBudget((current) => {
      return !current;
    });
  }, [setEnableAdaptivePointBudget]);

  return (
    <>
      {isAlphaUser && (
        <ToggleItemVisibility
          label="Adaptive Point Budget"
          isVisible={enableAdaptivePointBudget}
          toggleVisibility={toggleAdaptivePointBudget}
        />
      )}

      {!enableAdaptivePointBudget && (
        <PointBudgetSlider title="Point budget" step={500_000} min={2_000_000} max={40_000_000} />
      )}
    </>
  );
};
