import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { MediaViewerToolbar } from './MediaViewerToolbar';
import { Stack, Typography } from '@mui/material';
import { abyssColors } from '@/theme/colors';
import { isImageFile } from '@/utils';
import { Attachment } from '@/components/Analysis/modules/pointOfInterest/PointOfInterestTab/SelectedPoiContent/types';
import React, { ReactElement } from 'react';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';

type MediaTitleProps = {
  title?: string;
};

const MediaTitle = ({ title }: MediaTitleProps) => {
  const navbarHeight = useNavbarHeight();
  return (
    <Stack height={`${navbarHeight}px`} alignItems="center" justifyContent="center">
      <Typography color={abyssColors.neutral[200]}>{title}</Typography>
    </Stack>
  );
};

type MediaViewerProps = {
  children: ReactElement[] | ReactElement;
  mediaFiles: Attachment[];
  handleRemoveAttachment?: (argument0: number) => void;
  handleDownloadAttachment?: (argument0: number) => void;
};

export const MediaViewer = ({
  children,
  mediaFiles,
  handleDownloadAttachment,
  handleRemoveAttachment,
}: MediaViewerProps) => {
  return (
    <PhotoProvider
      maskOpacity={0.8}
      toolbarRender={({ onScale, scale, index, onClose }) => {
        if (!isImageFile(mediaFiles[index])) {
          return (
            <MediaViewerToolbar
              onClose={onClose}
              onDeleteClick={() => {
                if (handleRemoveAttachment) handleRemoveAttachment(index);
              }}
            />
          );
        }
        return (
          <MediaViewerToolbar
            onClose={onClose}
            onScale={onScale}
            scale={scale}
            onDownloadClick={() => {
              if (handleDownloadAttachment) handleDownloadAttachment(index);
            }}
            onDeleteClick={() => {
              if (handleRemoveAttachment) handleRemoveAttachment(index);
            }}
          />
        );
      }}
      overlayRender={({ index }) => {
        return <MediaTitle title={index < mediaFiles.length ? mediaFiles[index]?.name : ''} />;
      }}
    >
      {React.Children.map(children, (child, index) => {
        return (
          <>
            {isImageFile(mediaFiles[index]) ? (
              <PhotoView src={mediaFiles[index].base64}>{child}</PhotoView>
            ) : (
              child
            )}
          </>
        );
      })}
    </PhotoProvider>
  );
};
