import { generateFileDownload } from "@/utils/dom";

export const downloadLineFixingTableAsCsv = (arrayOfObjects: object[]) => {
    // Convert array of objects to CSV string
  
    const headers = Object.keys(arrayOfObjects[0])
    const csvContent = [
      headers.join(','),
      ...arrayOfObjects.map((object) =>
        headers
          .map((header) => {
            const value = object[header as keyof typeof object];
            return JSON.stringify(value === undefined ? '' : value);
          })
          .join(',')
      ),
    ].join('\n');
  
    generateFileDownload(csvContent, 'fixed_assemblies.csv');
  };