export const white = '#ffffff';

export const primary = {
  main: '#0D1824',
  dark: '#203752',
  light: '#6582A4',
  darkBlue: '#224063',
  blue: '#0C7CBB',
  LightGrey: '#00000061',
  LightBlue: '#0288D1',
  TintedBlue: '#F7F8FC',
  gray: '#6E8198',
  trueBlue: '#0959DB',
};

export const assemblyHighlightColors = {
  background: 'hsl(212, 26%, 52%, 0.3)',
  border: '#9AA7B6',
};

export const severityColor = {
  success: '#2E7D32',
  info: '#0288D1',
};

export const severityBgColor = {
  success: '#eaf2ea',
  info: '#e5f3fa',
};

// TODO: add remaining colors from design system
export const abyssColors = {
  primary: {
    50: '#F6F8FB',
    100: '#E7EEF6',
    200: '#B8CADD',
    300: '#7E9BBC',
    400: '#50739B',
    500: '#224063',
    600: '#203752',
    700: '#1A2C41',
    800: '#1B2838',
    900: '#131E2D',
  },
  secondary: {
    50: '#E0F8F2',
    100: '#C1F4E8',
    200: '#A9F2E0',
    300: '#7DEED3',
    400: '#4CE3C2',
    500: '#3BDAB7',
    600: '#35C2A3',
    700: '#169985',
    800: '#178170',
    900: '#09695B',
  },
  tertiary: {
    50: '#EEF1FF',
    100: '#E0E5FF',
    200: '#C7CCFE',
    300: '#A5AEFC',
    400: '#8381F8',
    500: '#6E63F1',
    600: '#5C46E5',
    700: '#4C38CA',
    800: '#4030A3',
    900: '#3A2E81',
  },
  neutral: {
    50: '#FFFFFF',
    100: '#F5F5F5',
    200: '#E5E5E5',
    300: '#D4D4D4',
    400: '#A3A3A3',
    500: '#737373',
    600: '#525252',
    700: '#404040',
    800: '#262626',
    900: '#171717',
  },
  error: {
    50: '#FFE7E7',
    100: '#FFCDCF',
    200: '#FFA4A7',
    300: '#FF9395',
    400: '#FF7E80',
    500: '#FF5D60',
    600: '#DD0004',
    700: '#AA0003',
    800: '#770002',
    900: '#440001',
  },
  warning: {
    50: '#FEFCE8',
    100: '#FEF9C3',
    200: '#FEF08A',
    300: '#FDE047',
    400: '#FACC15',
    500: '#EAB308',
    600: '#CA8A04',
    700: '#A16207',
    800: '#854D0E',
    900: '#713F12',
  },
  success: {
    50: '#F0FDF4',
    100: '#DCFCE7',
    200: '#BBF7D0',
    300: '#86EFAC',
    400: '#4ADE80',
    500: '#22C55E',
    600: '#16A34A',
    700: '#15803D',
    800: '#166534',
    900: '#14532D',
  },
};

export const uncertainBlisterColor = '888888';

export const drawerBackground = '#F7F8FC';

export const assemblyTabsBackground = '#ECEEF1';

export const viewerBackground = '#283238';

export const chipColor = { color: '#6D28D9', backgroundColor: '#F5F3FF' };

export const userManagementTableBorderColor = '#ECECEC';

export const versionHistoryColors = {
  POF: {
    background: '#EFF6FF',
    text: '#1D4ED8',
  },
  COF: {
    background: '#F5F3FF',
    text: '#6D28D9',
  },
  TransitionPeriod: {
    background: abyssColors.warning[50],
    text: abyssColors.warning[600],
  },
  default: {
    background: abyssColors.neutral[50],
    text: abyssColors.neutral[700],
  },
};

export const DAMAGE_CLASS_COLOR_MAP = {
  'N/A': '#DBE4ED',
  DC0: abyssColors.success[500],
  DC1: abyssColors.warning[300],
  DC2: abyssColors.warning[500],
  DC3: '#FF8C00',
  DC4: abyssColors.error[500],
  DC5: abyssColors.error[600],
  RC1: abyssColors.success[500],
  RC2: abyssColors.warning[500],
  RC3: '#FF8C00',
  RC4: abyssColors.error[500],
};

export const SC_CATEGORY_COLORS: Record<string, string> = {
  SC0: abyssColors.neutral[400],
  SC1: abyssColors.success[500],
  SC2: '#FF8C00',
  SC3: '#F44336',
};
