import React, { useState, useMemo, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TextField from '@mui/material/TextField';
import {
  Checkbox,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import {
  useAllDecksForFilterSideBarLazyQuery,
  useAllPaintRegionsLazyQuery,
} from '@/__generated__/graphql';
import {
  selectedFilteredDeckIds,
  selectedFilteredPaintRegionIds,
  structureFeatures,
} from '@/components/Analysis/state';
import { FiltersCount } from '../styles';
import { ViewerFeatures } from '@/types';
import { drawerBackground } from '@/theme/colors';

type FilterOptionsProps = {
  title: string;
  filterExpanded: boolean;
  handleChange: () => void;
  searchTerm: string;
  handleSearchTermChanged: (value: string) => void;
  filteredData: { id: string; name: string }[] | undefined;
  setFunction: Dispatch<SetStateAction<string[]>>;
  handleToggle: (
    id: string,
    disableAfterOneSelection: boolean,
    setFunction: Dispatch<SetStateAction<string[]>>
  ) => void;
  checkedIds: string[];
  loading: boolean;
  disableAfterOneSelection: boolean;
};

const FilterOptions = ({
  title,
  filterExpanded,
  handleChange,
  searchTerm,
  handleSearchTermChanged,
  filteredData,
  setFunction,
  handleToggle,
  checkedIds,
  loading,
  disableAfterOneSelection = false,
}: FilterOptionsProps) => {
  const allData = useMemo(() => {
    if (filteredData && filteredData.length > 0) {
      return filteredData?.reduce(
        (result: { id: string; name: string }[], item: { id: string; name: string }) => {
          result.push(item);
          return result;
        },
        []
      );
    }

    return [];
  }, [filteredData]);

  const renderOptions = useMemo(() => {
    if (loading) {
      return (
        <>
          {Array.from({ length: 5 }, (_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width="calc(100% - 20px)"
              height={20}
              sx={{ marginTop: '22px', marginLeft: '20px' }}
            />
          ))}
        </>
      );
    }
    if (allData && allData.length > 0) {
      return allData?.map((data) => (
        <ListItem key={data.id} disablePadding>
          <ListItemButton
            dense
            onClick={() => handleToggle(data.id, disableAfterOneSelection, setFunction)}
            sx={{ padding: '0px 8px', gap: '8px' }}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox
                edge="start"
                disableRipple
                checked={checkedIds.includes(data.id)}
                disabled={disableAfterOneSelection}
              />
            </ListItemIcon>
            <ListItemText id={data.id} primary={data.name} />
          </ListItemButton>
        </ListItem>
      ));
    }
    return (
      <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 2 }}>
        <Typography>No options found</Typography>
      </Stack>
    );
  }, [allData, checkedIds, disableAfterOneSelection, handleToggle, loading, setFunction]);

  return (
    <Accordion
      sx={{
        margin: 0,
        boxShadow: 'none',
        backgroundColor: drawerBackground,
        '.Mui-expanded&:before': { opacity: 1 },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
        '@media(max-width: 1728px)': {
          '& .MuiAccordionSummary-root': {
            minHeight: '32px',
          },
        },
      }}
      expanded={filterExpanded}
      onChange={handleChange}
      disableGutters
    >
      <AccordionSummary
        sx={{ padding: '0', backgroundColor: drawerBackground }}
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel1a-content"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography sx={{ fontWeight: 500, paddingLeft: '1rem' }} variant="body2">
            {title}
          </Typography>
          {checkedIds.length > 0 && <FiltersCount>{checkedIds.length}</FiltersCount>}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ margin: 0 }}>
        <Stack sx={{ marginX: 1 }}>
          {((allData && allData.length > 4) || searchTerm) && (
            <TextField
              label={`Search ${title}`}
              type="search"
              variant="standard"
              value={searchTerm}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(event) => handleSearchTermChanged(event.target.value)}
            />
          )}
        </Stack>
        <List
          sx={{
            width: '100%',
            bgcolor: drawerBackground,
            pt: 0,
            overflowY: 'auto',
            maxHeight: '260px',
          }}
        >
          {renderOptions}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export const DynamicFilters = () => {
  const structureId = useGetStructureId();
  const [deckSearchTerm, setDeckSearchTerm] = useState('');
  const [paintRegionSearchTerm, setPaintRegionSearchTerm] = useState('');
  const [isDeckFilterExpanded, setIsDeckFilterExpanded] = useState(false);
  const [decksLoading, setDecksLoading] = useState(false);
  const [paintRegionsLoading, setPaintRegionsLoading] = useState(false);
  const [isPaintRegionFilterExpanded, setIsPaintRegionFilterExpanded] = useState(false);
  const [deckData, setDeckData] = useState<{ id: string; name: string }[] | undefined>([]);
  const [paintRegionData, setPaintRegionData] = useState<
    { id: string; name: string }[] | undefined
  >([]);
  const [selectedDeckIds, setSelectedDeckIds] = useRecoilState(selectedFilteredDeckIds);
  const [selectedPaintRegionIds, setSelectedPaintRegionIds] = useRecoilState(
    selectedFilteredPaintRegionIds
  );
  const structureInfo = useRecoilValue(structureFeatures);

  const [getAllDecksData] = useAllDecksForFilterSideBarLazyQuery({
    variables: { structureId },
  });
  const [getAllPaintRegionsData] = useAllPaintRegionsLazyQuery({
    variables: { structureId, deckIds: selectedDeckIds },
  });

  useEffect(() => {
    if (isDeckFilterExpanded) {
      setDecksLoading(true);
      getAllDecksData()
        .then(({ data }) => {
          setDeckData(data?.allDecks.map(({ id, name }) => ({ id, name })));
          setDecksLoading(false);
        })
        .catch(() => setDecksLoading(false));
    }
  }, [getAllDecksData, isDeckFilterExpanded]);

  useEffect(() => {
    if (isPaintRegionFilterExpanded) {
      setPaintRegionsLoading(true);
      getAllPaintRegionsData()
        .then(({ data }) => {
          setPaintRegionData(data?.allPaintRegions.map(({ id, name }) => ({ id, name })));
          setPaintRegionsLoading(false);
        })
        .catch(() => setDecksLoading(false));
    }
  }, [getAllPaintRegionsData, isPaintRegionFilterExpanded]);

  const filteredDeckData = useMemo(() => {
    if (deckSearchTerm === '') {
      return deckData;
    }
    return deckData?.filter((data) =>
      data.name.toLowerCase().includes(deckSearchTerm.toLowerCase())
    );
  }, [deckData, deckSearchTerm]);

  const filteredPaintRegionData = useMemo(() => {
    if (paintRegionSearchTerm === '') {
      return paintRegionData;
    }
    return paintRegionData?.filter((data) =>
      data.name.toLowerCase().includes(paintRegionSearchTerm.toLowerCase())
    );
  }, [paintRegionData, paintRegionSearchTerm]);

  const handleDeckChange = useCallback(() => {
    setIsDeckFilterExpanded(!isDeckFilterExpanded);
  }, [isDeckFilterExpanded]);

  const handlePaintRegionChange = useCallback(() => {
    setIsPaintRegionFilterExpanded(!isPaintRegionFilterExpanded);
  }, [isPaintRegionFilterExpanded]);

  const handleDeckSearchTermChange = useCallback(
    (value: string) => {
      setDeckSearchTerm(value);
    },
    [setDeckSearchTerm]
  );

  const handlePaintRegionSearchTermChange = useCallback(
    (value: string) => {
      setPaintRegionSearchTerm(value);
    },
    [setPaintRegionSearchTerm]
  );

  const handleToggle = useCallback(
    (
      id: string,
      disableAfterOneSelection: boolean,
      setFunction: Dispatch<SetStateAction<string[]>>
    ) => {
      setFunction((previousIds) => {
        if (disableAfterOneSelection) {
          return previousIds.includes(id) ? [] : previousIds.length === 0 ? [id] : previousIds;
        }

        return previousIds.includes(id)
          ? previousIds.filter((previousId) => previousId !== id)
          : [...previousIds, id];
      });
    },
    []
  );

  return (
    <Stack data-testid="filters-container">
      {structureInfo?.features?.viewer.includes(ViewerFeatures.DECK_FILTER) && (
        <FilterOptions
          title="Decks"
          filterExpanded={isDeckFilterExpanded}
          handleChange={handleDeckChange}
          searchTerm={deckSearchTerm}
          handleSearchTermChanged={handleDeckSearchTermChange}
          filteredData={filteredDeckData}
          setFunction={setSelectedDeckIds}
          handleToggle={handleToggle}
          checkedIds={selectedDeckIds}
          loading={decksLoading}
          disableAfterOneSelection={false}
        />
      )}
      {structureInfo?.features?.viewer.includes(ViewerFeatures.PAINT_BLOCK_FILTER) && (
        <FilterOptions
          title="Paint Blocks"
          filterExpanded={isPaintRegionFilterExpanded}
          handleChange={handlePaintRegionChange}
          searchTerm={paintRegionSearchTerm}
          handleSearchTermChanged={handlePaintRegionSearchTermChange}
          filteredData={filteredPaintRegionData}
          setFunction={setSelectedPaintRegionIds}
          handleToggle={handleToggle}
          checkedIds={selectedPaintRegionIds}
          loading={paintRegionsLoading}
          disableAfterOneSelection={true}
        />
      )}

      <Divider />
    </Stack>
  );
};
