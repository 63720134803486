import { SxProps, Typography, styled } from '@mui/material';

export const LinkTypography = styled(Typography)({
  display: 'inline',
  color: 'blue',
  cursor: 'pointer',
  textDecoration: 'underline',
});

export const ValidationsErrorDialogStyles: SxProps = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '95vw',
  padding: 3,
};
