import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
  TablePagination,
  Skeleton,
  TextField,
  InputAdornment,
  Stack,
} from '@mui/material';
import { abyssColors } from '@/theme/colors';
import { useGetAssemblyDataForInsightsQuery } from '@/__generated__/graphql';
import { useViewpointsByAssembly } from '@/components/Analysis/Viewer/hooks';
import { formatEnumForDisplay } from '@/utils';
import { TablePaginationContainer } from '@/components/UserManagement/styles';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as state from '@/components/Analysis/state';
import { mapLocationToCurrentImage } from '@/components/Analysis/Viewer/hooks/useImageFromLocationId';
import { CardContainer } from '@/components/Analysis/Idms/styles';
import { Search as SearchIcon } from '@mui/icons-material';
import { useDefectLegendColors } from '../../../hooks/useDefectLegendColors';
import {
  isSphericalAnalysedRelatedToAssembly,
  useAssemblySeenByViewpoints,
} from '@/components/Analysis/Viewer/hooks/useAssemblySeenByViewpoints';
import { ProgressBarWithText } from '@/components/shared/ProgressBarWithText';
import { paginationStyles, tableCellStyles } from './styles';
import { cellColorIndicatorStyles } from '../styles';

const columns = [
  { id: 'name', label: 'Viewpoints', width: '34%' },
  { id: 'corrosionCategory', label: 'Corrosion Category', width: '33%' },
  { id: 'distanceToAsset', label: 'Distance to Asset', width: '30%' },
];

type Props = {
  assemblyId: string | undefined;
  setViewPointsCount: React.Dispatch<React.SetStateAction<number>>;
};

export const ViewpointsTable = ({ assemblyId, setViewPointsCount }: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const setSelectedSpherical = useSetRecoilState(state.selectedSpherical);
  const allViewpoints = useRecoilValue(state.structureLocations);
  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);
  const { defectLegendColors, loading: colorsLoading } = useDefectLegendColors();

  const { data: assemblyViewpointsData, loading } = useGetAssemblyDataForInsightsQuery({
    variables: { assemblyId },
    skip: !assemblyId,
    errorPolicy: 'all',
  });

  const assemblyViewpoints = useViewpointsByAssembly(
    selectedAssemblyId,
    assemblyViewpointsData?.assembly?.seenByViewpoints ?? undefined
  );

  const assemblySeenByViewpoint = useAssemblySeenByViewpoints(assemblyId);

  useEffect(() => {
    setViewPointsCount(assemblyViewpoints?.length ?? 0);
  }, [assemblyViewpoints, setViewPointsCount]);

  const analysedViewpointPercentage = useMemo(() => {
    if (!assemblyViewpoints || assemblyViewpoints.length === 0) {
      return 0;
    }
    const numerator = assemblyViewpoints.filter((viewpoint) =>
      isSphericalAnalysedRelatedToAssembly({
        locationId: viewpoint.location.id,
        seenByViewpoints: assemblySeenByViewpoint,
      })
    ).length;
    const denominator = assemblyViewpoints.length;
    return (numerator / denominator) * 100;
  }, [assemblySeenByViewpoint, assemblyViewpoints]);

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    },
    [setPage, setRowsPerPage]
  );

  const handleChangePage = useCallback(
    (_: unknown, newPage: number) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
    setPage(0);
  }, []);

  const goToViewpoint = useCallback(
    (viewpointId: string) => {
      const selectedViewpoint = allViewpoints?.find((viewpoint) => viewpoint.id === viewpointId);
      if (selectedViewpoint?.pose) {
        setSelectedSpherical(mapLocationToCurrentImage(selectedViewpoint));
      }
    },
    [allViewpoints, setSelectedSpherical]
  );

  const filteredViewpoints = useMemo(() => {
    if (!assemblyViewpoints) return [];
    if (!searchTerm) return assemblyViewpoints;
    return assemblyViewpoints.filter((viewpoint) =>
      viewpoint.location.name.toLowerCase().includes(searchTerm)
    );
  }, [assemblyViewpoints, searchTerm]);

  const paginatedViewpoints = useMemo(
    () => filteredViewpoints?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? [],
    [filteredViewpoints, page, rowsPerPage]
  );

  return (
    <>
      <TextField
        data-testid="search-field"
        fullWidth
        placeholder="Search viewpoints"
        variant="standard"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          sx: { height: '40px' },
        }}
        sx={{ width: '100%', mb: 2 }}
        onChange={handleSearch}
      />
      {paginatedViewpoints.length > 0 && (
        <ProgressBarWithText
          color="secondary"
          direction="column"
          value={analysedViewpointPercentage}
          text="of the Viewpoints analyzed"
        />
      )}
      <Paper elevation={0} sx={{ padding: 1, borderRadius: 2 }}>
        <TableContainer>
          {loading || colorsLoading ? (
            <CardContainer>
              <Skeleton variant="rectangular" width="100%" height={300} />
            </CardContainer>
          ) : paginatedViewpoints.length > 0 ? (
            <Table sx={{ borderCollapse: 'collapse' }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      sx={{
                        ...tableCellStyles,
                        height: 'auto',
                        width: column.width,
                        textAlign: 'left',
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 10, fontWeight: 700, textTransform: 'uppercase' }}
                      >
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {paginatedViewpoints.map((viewpoint, index) => {
                  const corrosionLevel = formatEnumForDisplay(
                    viewpoint?.corrosion?.primaryCategory
                  );
                  const corrosionColor = corrosionLevel
                    ? defectLegendColors[corrosionLevel]
                    : defectLegendColors?.Clean;
                  return (
                    <TableRow key={index}>
                      <TableCell
                        onClick={() => goToViewpoint(viewpoint.location.id)}
                        align="left"
                        sx={{
                          ...tableCellStyles,
                          height: 'auto',
                          width: columns[0].width,
                          cursor: 'pointer',
                          ':hover': { backgroundColor: abyssColors.primary[50] },
                        }}
                      >
                        <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
                          {viewpoint.location.name}
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{ ...tableCellStyles, height: 'auto', width: columns[1].width }}
                      >
                        <Stack direction="row">
                          <Box
                            sx={{ ...cellColorIndicatorStyles, backgroundColor: corrosionColor }}
                          />
                          <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
                            {corrosionLevel}
                          </Typography>
                        </Stack>
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{ ...tableCellStyles, height: 'auto', width: columns[2].width }}
                      >
                        <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
                          {viewpoint.distanceDisplayText}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
              No Viewpoints associated with this assembly{' '}
            </Typography>
          )}
          {paginatedViewpoints.length > 0 && (
            <TablePaginationContainer>
              <TablePagination
                slotProps={{ select: { sx: { m: 0, p: 0, fontSize: '1rem' } } }}
                sx={paginationStyles}
                count={filteredViewpoints?.length ?? 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[
                  { label: '10', value: 10 },
                  { label: '25', value: 25 },
                  { label: '100', value: 100 },
                  { label: 'All', value: filteredViewpoints?.length ?? 0 },
                ]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TablePaginationContainer>
          )}
        </TableContainer>
      </Paper>
    </>
  );
};
