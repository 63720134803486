import { Box, Skeleton, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { calculatePercentage } from '@/utils';
import * as state from '@/components/Analysis/state';
import { localiseAreaMeasurement } from '@/utils/unitSystem';
import { useDefectLegendColors } from '../../../hooks/useDefectLegendColors';
import { Detail } from '../StyledComponents';
import { abyssColors } from '@/theme/colors';
import { CircularProgress } from '@/components/shared/CircularProgress';

type Props = {
  title: string;
  valueInt: string | number;
  area: number | string;
  subType?: string;
};

export const EquipmentCondition = ({ title, valueInt, area }: Props) => {
  const value = Number.parseFloat(valueInt.toString()).toFixed(3);
  const assemblyRatio: string = useMemo(() => {
    const precentageOrNan = calculatePercentage(Number(area), Number.parseFloat(value));
    const percentage = Number.isNaN(precentageOrNan) ? '0.00' : `${precentageOrNan}%`;
    return percentage;
  }, [area, value]);

  const { defectLegendColors, loading: colorsLoading } = useDefectLegendColors();

  const unitSystem = useRecoilValue(state.unitSystem);

  if (colorsLoading) return <Skeleton />;

  const corrosionValue = Number.isNaN(value)
    ? `${localiseAreaMeasurement(unitSystem, Number(valueInt))}`
    : `${localiseAreaMeasurement(unitSystem, Number(value))}`;

  return (
    <Stack direction="row" width="100%">
      <Box width="100%">
        <Detail my={0.8} title={title} value={`${corrosionValue} - ${assemblyRatio}`} />
      </Box>
      <Box ml={0.5}>
        <CircularProgress
          value={Number(assemblyRatio?.replace('%', '')) ?? 0}
          backgroundColor={abyssColors.primary[100]}
          progressColor={defectLegendColors[title] ?? 'black'}
          size={28}
        />
      </Box>
    </Stack>
  );
};
