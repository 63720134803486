import { Box, Modal, Paper, SxProps, Typography } from '@mui/material';
import { GenericConfirmationDialogStyles } from './styles';

type Props = {
  open: boolean;
  onClose: () => void;
  sx?: SxProps;
  title: string;
  description: string;
  children: React.ReactNode | React.ReactNode[];
  body?: React.ReactNode;
};

export const GenericConfirmationDialog = ({
  open,
  onClose,
  sx,
  title,
  description,
  children,
  body,
}: Props) => {
  const styles = {
    ...GenericConfirmationDialogStyles,
    // No its not useless, it allows us to override the styles ????
    // eslint-disable-next-line unicorn/no-useless-fallback-in-spread
    ...((sx as SxProps) ?? {}),
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper sx={styles}>
        <Typography fontWeight={'bold'} fontSize={20} my={2}>
          {title}
        </Typography>
        <Typography>{description}</Typography>
        {body || (
          <Typography>
            An error occurred while processing your request. Please contact support
          </Typography>
        )}
        <Box gap={2} my={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {Array.isArray(children) ? children.map((child) => child) : children}
        </Box>
      </Paper>
    </Modal>
  );
};
