import {
  Button,
  Chip,
  TextField,
  FormLabel,
  Menu,
  Accordion,
  FormControlLabel,
  Checkbox,
  styled as muiStyled,
} from '@mui/material';
import styled from 'styled-components';
import { abyssColors } from '@/theme/colors';

export const FilterButton = muiStyled(Button)(() => ({
  padding: '4px 4px 4px 16px',
  borderRadius: '4px',
  border: '1px solid #6582A4',
  width: '121px',
  height: '32px',
  background: abyssColors.primary[50],
  '@media (max-width: 1728px)': {
    height: 'fit-content',
    paddingLeft: '8px',
    width: '100px',
  },
}));

export const AddFiltersButton = muiStyled(Button)(() => ({
  width: '32px',
  height: '32px',
  borderRadius: '4px',
  border: '1px solid #B7C1CC',
  background: abyssColors.primary[100],
  minWidth: 'auto',
  color: abyssColors.primary[500],
}));

export const FilterChip = muiStyled(Chip)(() => ({
  lineHeight: 'normal',
  fontSize: '1rem',
  background: abyssColors.primary[100],
  color: abyssColors.primary[500],
  height: '24px',
  borderRadius: '16px',
  '& .MuiChip-label': {
    paddingLeft: '10px',
    paddingRight: '10px',
    marginBottom: '-1px',
  },
  '& .MuiChip-deleteIcon': { color: abyssColors.primary[300], fontSize: '1.6rem' },
}));

export const FilterAccordian = muiStyled(Accordion)(() => ({
  borderRadius: '4px',
  background: 'rgba(101, 130, 164, 0.10)',
  boxShadow: 'none',
  '&::before': {
    opacity: 0,
  },
}));

export const FilterTextField = muiStyled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    padding: '0px !important',
    paddingLeft: '12px !important',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiInputBase-root': {
    fontSize: '1.4rem',
    height: 32,
  },
  '& input::placeholder': {
    fontSize: '1.4rem',
    maxWidth: '10rem',
  },
  '& input': {
    padding: '0px !important',
  },
}));

export const FilterFormLabel = muiStyled(FormLabel)(() => ({
  fontSize: '1rem',
  textTransform: 'uppercase',
  color: '#98A4B1',
  fontWeight: 500,
  marginBottom: '4px',
  lineHeight: 'normal',
}));

export const FilterMenu = muiStyled(Menu)(() => ({
  marginTop: '6px',
  padding: 0,
  '& .MuiMenu-paper': {
    minWidth: '156px',
    padding: 0,
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.30)',
    borderRadius: '4px',
  },
  '& .MuiCheckbox-root': { padding: '0px !important' },
}));

export const FilterFormControlLabel = muiStyled(FormControlLabel)(() => ({
  marginLeft: '0px',
  marginRight: '0px',
  height: '22px',
  '& .MuiFormControlLabel-label': {
    color: abyssColors.primary[500],
    fontSize: '1.2rem',
    lineHeight: 'normal',
  },
}));

export const FilterCheckBox = muiStyled(Checkbox)(() => ({
  marginLeft: '8px',
  marginRight: '8px',
  '&.Mui-checked': {
    color: abyssColors.primary[500],
  },
  '& .MuiSvgIcon-root': { fontSize: '1.6rem' },
}));

export const FilterDetails = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const ButtonLabel = styled.span`
  font-weight: 400;
  color: #425265;
  text-transform: none;
  line-height: normal;
`;

export const AdditionalFilters = styled.span`
  line-height: normal;
  font-size: 1rem;
  margin-right: 10px;

  cursor: pointer;
`;

export const FiltersCount = styled.div`
  border-radius: 18px;
  background: ${abyssColors.primary[100]};
  padding: 2px 8px;
  display: flex;
  align-items: center;
  margin-left: 4px;
  height: 16px;
  color: ${abyssColors.primary[700]};
  line-height: normal;
  font-size: 1.2rem;
  font-weight: 500;
`;

export const ToolTipProps = {
  tooltip: {
    sx: {
      backgroundColor: 'rgb(0,0,0)',
      color: 'white',
      opacity: 1,
      fontSize: '1rem',
      display: 'flex',
      alignItems: 'center',
      paddingY: '10px',
      height: '28px',
      borderRadius: '4px',
      lineHeight: 'normal',
      fontWeight: 500,
    },
  },
  arrow: {
    sx: { color: 'black' },
  },
};

export const FilterMenuListProps = {
  sx: { paddingY: '4px', display: 'flex', flexDirection: 'column', gap: '4px' },
};

export const searchStyles = {
  '& .MuiOutlinedInput-input': {
    paddingY: '6px',
  },
  '& .MuiOutlinedInput-root ': {
    paddingLeft: '8px',
  },
  '& .MuiInputBase-root': {
    fontSize: '1.4rem',
  },
  '& input::placeholder': {
    fontSize: '1.4rem',
    lineHeight: 'normal',
  },
  '& fieldset': {
    border: '1px solid #B7C1CC',
  },
};
