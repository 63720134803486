import { Box, Typography } from '@mui/material';
import { NotificationImportant } from '@mui/icons-material';
import {
  EqiupmentCardFieldTypeEnum,
  EquipmentCardPositionEnum,
  EquipmentDetailTypeEnum,
  Maybe,
  OptionFieldTypeEnum,
  TemplateFieldTypeEnum,
} from '@/__generated__/graphql';
import { CriticalityLevelMapper, AlarmLevels } from '../common';
import { InlineTypography, percentageIndicatorStyles, textWithTitleStyles } from './styles';

type EquipmentCardTemplateRendererProps = {
  data: {
    name: string | undefined;
    value: Maybe<string> | AlarmLevels | undefined;
    type:
      | TemplateFieldTypeEnum
      | OptionFieldTypeEnum
      | EqiupmentCardFieldTypeEnum
      | EquipmentDetailTypeEnum
      | undefined;
    position: EquipmentCardPositionEnum | undefined;
  };
};

export const EquipmentCardTemplateRenderer = ({ data }: EquipmentCardTemplateRendererProps) => {
  switch (data?.type) {
    case EqiupmentCardFieldTypeEnum.SimpleText: {
      return <Typography sx={{ fontSize: '1.2rem' }}>{data.value}</Typography>;
    }
    case EqiupmentCardFieldTypeEnum.AlarmLevel: {
      const criticalityLevelMapper =
        CriticalityLevelMapper[(data?.value as AlarmLevels) || AlarmLevels.CLEAN];
      return (
        <InlineTypography sx={{ fontSize: '1.4rem', fontWeight: 700 }}>
          <NotificationImportant fontSize="small" sx={{ color: criticalityLevelMapper?.color }} />
          {criticalityLevelMapper?.alarmLevel === AlarmLevels.CLEAN
            ? '-'
            : criticalityLevelMapper?.alarmLevel}
        </InlineTypography>
      );
    }
    case EqiupmentCardFieldTypeEnum.DamageClass: {
      return <Typography sx={{ fontSize: '1.4rem' }}>{data.value}</Typography>;
    }
    case 'TEXT_WITH_TITLE': {
      return (
        <InlineTypography sx={{ fontSize: '1.2rem' }}>
          {data.name}:
          <InlineTypography sx={textWithTitleStyles}>{data.value ?? '-'}</InlineTypography>
        </InlineTypography>
      );
    }
    case EqiupmentCardFieldTypeEnum.PercentageIndicator: {
      return (
        <InlineTypography sx={percentageIndicatorStyles}>
          {data.name}:
          <InlineTypography sx={{ fontWeight: 700, marginLeft: 0.5 }}>
            {data.value ? `${data.value}%` : '-'}
          </InlineTypography>
        </InlineTypography>
      );
    }
    default:
      return <Box sx={{ backgroundColor: 'transparent', color: 'transparent' }}>Default</Box>;
  }
};
