import { Box } from '@mui/material';

type Props = {
  value: number;
  backgroundColor: string;
  progressColor: string;
  width?: number;
  size?: number;
};

export const CircularProgress = ({
  value,
  backgroundColor,
  progressColor,
  width = 2,
  size = 40,
}: Props) => {
  const radius = (size - 8) / 2;
  const circumference = 2 * Math.PI * radius;

  const strokeDashoffset = circumference - (value / 100) * circumference;

  return (
    <Box position="relative" width={size} height={size}>
      <svg width={size} height={size} style={{ position: 'absolute', top: 0, left: 0 }}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={backgroundColor}
          strokeWidth={width}
          fill="none"
        />
      </svg>

      <svg
        width={size}
        height={size}
        style={{ position: 'absolute', top: 0, left: 0, transform: 'rotate(-90deg)' }}
      >
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={progressColor}
          strokeWidth={width + 1}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
    </Box>
  );
};
