import { GenericConfirmationDialog } from '@/components/Analysis/shared/GeneriConfirmationDialog';
import { Box, Button, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import * as state from './state';
import { SUPPORT_ERROR_MESSAGE } from '@/constants';
import { useMemo } from 'react';
export const LineFixingErrorDialog = () => {
  const [lineFixingState, setLineFixingState] = useRecoilState(state.lineFixingState);

  const onClose = () => {
    setLineFixingState({
      ...lineFixingState,
      error: undefined,
      state: 'closed',
    });
  };

  const duplicationErrorRowsText: React.ReactNode | undefined = useMemo(() => {
    if (lineFixingState.error?.extensions?.type !== 'duplicate') return undefined;
    if (!lineFixingState.error?.extensions?.errorKey) return undefined;

    const duppedRows = (lineFixingState.error?.extensions?.error as object[]) || [];

    // Error key is provided by the backend to determine what key of the row the error was found in
    // It can only be a value from 'LINE_FIXING_CSV_HEADERS'
    const errorKey = String(lineFixingState.error?.extensions?.errorKey) as keyof typeof duppedRows[0];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (
      <Box mt={2}>
        {duppedRows.map((row) => {
          const text = errorKey in row ? (row[errorKey] as string) : '';
          return <Typography key={text}>{text}</Typography>;
        })}
      </Box>
    );
  }, [lineFixingState.error?.extensions]);

  return (
    <GenericConfirmationDialog
      open={lineFixingState.state === 'generic-csv-error'}
      onClose={onClose}
      title="Line Fixing Error"
      description={
        typeof lineFixingState.error?.message === 'string'
          ? lineFixingState.error?.message
          : SUPPORT_ERROR_MESSAGE
      }
      body={duplicationErrorRowsText}
    >
      <Button onClick={onClose}>Got it</Button>
    </GenericConfirmationDialog>
  );
};
