import * as React from 'react';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {
  Autocomplete,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  applyFilters as applyFiltersState,
  selectedTemplateFiltersState,
} from '@/components/Analysis/state';
import { useFilteredOptionsLazyQuery } from '@/__generated__/graphql';
import { abyssColors, drawerBackground } from '@/theme/colors';
import { FilterChip } from '../FilterAndSearchPanel/styles';
import { Clear } from '@mui/icons-material';

type Props = {
  templateId: string;
  name: string;
  templateFieldId?: string;
  serverSide: boolean;
  isExpanded: boolean;
  templateFieldKey: string;
  selected: { [key: string]: string[] };
};

type FilterOptionVariable = {
  templateId: string;
  fieldKey: string;
  fieldId?: string;
  skip?: number;
  limit?: number;
  count?: number;
};

export const SearchList = ({
  templateId,
  name,
  templateFieldKey,
  serverSide,
  isExpanded,
  selected,
}: Props) => {
  const [searchTerms, setSearchTerms] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const [unfinishedSearchTerm, setUnfinishedSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedTemplateFilters, setSelectedTemplateFilters] = useRecoilState(
    selectedTemplateFiltersState
  );
  const setApplyFilters = useSetRecoilState(applyFiltersState);
  const list = React.useRef<string[]>([]);

  const [fetchFilteredOptions] = useFilteredOptionsLazyQuery({ fetchPolicy: 'no-cache' });
  const [hasMore, setHasMore] = useState(true);
  const [, setSkipItems] = useState(0);

  const loadMoreOptions = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);

    setSkipItems((previousSkip) => {
      const nextSkip = previousSkip + 10;

      const filterOptionsVariables: FilterOptionVariable = {
        templateId,
        fieldKey: templateFieldKey,
        skip: nextSkip,
        limit: 10,
      };

      fetchFilteredOptions({ variables: filterOptionsVariables })
        .then(({ data }) => {
          if (data && data?.filteredOptions[0]?.options?.length > 0) {
            list.current = [...list.current, ...data.filteredOptions[0].options];
            setFilteredOptions((previousOptions) => [
              ...previousOptions,
              ...data.filteredOptions[0].options,
            ]);
            if (data.filteredOptions[0].options.length < 10) setHasMore(false);
          } else {
            setHasMore(false);
          }
        })
        .finally(() => setLoading(false));

      return nextSkip;
    });
  }, [loading, hasMore, templateId, templateFieldKey, fetchFilteredOptions]);

  const fetchInitialOptions = useCallback(async () => {
    const filterOptionsVariables: FilterOptionVariable = {
      templateId,
      fieldKey: templateFieldKey,
    };
    if (serverSide) {
      filterOptionsVariables.count = 10;
    }
    setLoading(true);
    await fetchFilteredOptions({ variables: filterOptionsVariables })
      .then(({ data }) => {
        if (data?.filteredOptions[0]?.options) {
          list.current = data.filteredOptions[0].options;
          setFilteredOptions((previousOptions) => [
            ...previousOptions,
            ...data.filteredOptions[0].options,
          ]);
        }
      })
      .finally(() => setLoading(false));
  }, [fetchFilteredOptions, serverSide, templateFieldKey, templateId]);

  useEffect(() => {
    if (isExpanded && list.current.length === 0) {
      fetchInitialOptions();
    }
  }, [fetchInitialOptions, isExpanded]);

  const filterOptions = useCallback(async () => {
    if (list.current.length === 0) return;
    if (searchTerms === '') {
      setFilteredOptions(list.current);
      return;
    }
    if (serverSide) {
      const { data } = await fetchFilteredOptions({
        variables: {
          templateId,
          fieldKey: templateFieldKey,
          q: searchTerms,
          count: 10,
        },
      });
      if (data?.filteredOptions[0]?.options) {
        setFilteredOptions(data.filteredOptions[0].options);
      }
    } else {
      const options = list.current.filter((item: string) =>
        item.toLowerCase().includes(searchTerms.toLowerCase() ?? '')
      );
      setFilteredOptions(options);
    }
  }, [fetchFilteredOptions, searchTerms, serverSide, templateFieldKey, templateId]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setSearchTerms(unfinishedSearchTerm);
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [setSearchTerms, unfinishedSearchTerm]);

  useEffect(() => {
    filterOptions();
  }, [filterOptions, searchTerms]);

  const checked = useMemo(() => {
    return selected[templateFieldKey] ?? [];
  }, [selected, templateFieldKey]);

  const handleToggle = useCallback(
    (key: string, value: string) => () => {
      setApplyFilters(false);
      if (selectedTemplateFilters.length > 0) {
        if (key) {
          const filterExist = selectedTemplateFilters.findIndex((filter) => filter.id === key);
          if (filterExist === -1) {
            setSelectedTemplateFilters([...selectedTemplateFilters, { id: key, value: [value] }]);
          } else {
            const filterValue = selectedTemplateFilters[filterExist].value;
            let filters = selectedTemplateFilters[filterExist];
            const newSelectedFilters = selectedTemplateFilters.filter(
              (filterObject) => filterObject !== filters
            );
            const newFilterValue = filterValue.filter((v) => v !== value);

            if (newFilterValue.length === 0) {
              setSelectedTemplateFilters([...newSelectedFilters]);
            } else {
              filters = filterValue.includes(value)
                ? { id: key, value: newFilterValue }
                : { id: key, value: [...filterValue, value] };

              setSelectedTemplateFilters([...newSelectedFilters, filters]);
            }
          }
        }
      } else if (selectedTemplateFilters.length === 0 && key) {
        setSelectedTemplateFilters([{ id: key, value: [value] }]);
      }
    },
    [selectedTemplateFilters, setSelectedTemplateFilters, setApplyFilters]
  );
  const handleSearchTermChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUnfinishedSearchTerm(event.target.value);
    },
    [setUnfinishedSearchTerm]
  );

  const handleDeleteChip = useCallback(
    (key: string, itemToDelete: string) => {
      setSelectedTemplateFilters((selectedFilters) =>
        selectedFilters
          .map((selectedFilter) => {
            if (selectedFilter.id === key) {
              return {
                ...selectedFilter,
                value: selectedFilter.value.filter((filterValue) => filterValue !== itemToDelete),
              };
            }
            return selectedFilter;
          })
          .filter((item) => item.value.length > 0)
      );
    },
    [setSelectedTemplateFilters]
  );

  const renderOptions = useMemo(() => {
    if (filteredOptions.length > 0) {
      return filteredOptions.map((value: string) => {
        const labelId = `checkbox-list-label-${name}-${templateFieldKey}`;
        const isChecked = checked.includes(value);
        return (
          <ListItem key={value} disablePadding data-testid="template-field-option" disableGutters>
            <ListItemButton
              role={undefined}
              dense
              onClick={handleToggle(templateFieldKey, value)}
              sx={{ padding: '0px 8px', gap: '8px' }}
            >
              <ListItemIcon sx={{ minWidth: 0 }}>
                <Checkbox
                  edge="start"
                  disableRipple
                  checked={isChecked}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItemButton>
          </ListItem>
        );
      });
    }
    if (!loading)
      return (
        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 2 }}>
          <Typography>No options found</Typography>
        </Stack>
      );
  }, [checked, filteredOptions, handleToggle, loading, name, templateFieldKey]);

  return (
    <>
      {loading || (
        <Stack sx={{ marginX: 1 }}>
          {(filteredOptions.length > 4 || unfinishedSearchTerm) && (
            <Autocomplete
              disableClearable
              options={[]}
              freeSolo
              multiple
              value={checked}
              renderTags={(value) =>
                value.map((option) => (
                  <FilterChip
                    sx={{
                      backgroundColor: abyssColors.primary[100],
                      color: abyssColors.primary[500],
                      mb: '0.5rem',
                      width: 'fit-content',
                    }}
                    deleteIcon={<CancelIcon style={{ color: abyssColors.primary[500] }} />}
                    label={checked.find((checkedItem) => checkedItem === option) ?? value}
                    onDelete={() => handleDeleteChip(templateFieldKey, option)}
                    size="small"
                    key={option}
                  />
                ))
              }
              renderInput={(parameters) => (
                <TextField
                  {...parameters}
                  data-testid="template-field-search-list"
                  label={`Search ${name}`}
                  variant="standard"
                  value={unfinishedSearchTerm}
                  fullWidth
                  onInput={handleSearchTermChanged}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    ...parameters.InputProps,
                    endAdornment: (
                      <Stack gap="0.4rem" direction="row" alignItems="center">
                        {unfinishedSearchTerm && (
                          <IconButton
                            sx={{ padding: 0 }}
                            disableRipple
                            onClick={() => {
                              setUnfinishedSearchTerm('');
                            }}
                          >
                            <Clear sx={{ fontSize: '1.6rem', color: abyssColors.primary[500] }} />
                          </IconButton>
                        )}
                        <SearchIcon sx={{ color: abyssColors.neutral[500] }} />
                      </Stack>
                    ),
                    disableUnderline: true,
                  }}
                />
              )}
              sx={{
                '& .MuiChip-root': {
                  marginRight: '12px',
                  marginLeft: '-8px',
                },
                '& fieldset': {
                  border: 'none',
                },
                marginBottom: '0.8rem',
              }}
            />
          )}
        </Stack>
      )}
      <List
        sx={{
          width: '100%',
          bgcolor: drawerBackground,
          pt: 0,
          overflowY: 'auto',
          maxHeight: '260px',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        {loading || <Divider />}
        {renderOptions}

        {hasMore && serverSide && (
          <Stack onClick={loadMoreOptions}>
            {loading ? (
              <CircularProgress style={{ display: 'block', margin: '10px auto' }} size={20} />
            ) : (
              <Typography
                style={{
                  display: 'block',
                  margin: '10px auto',
                  padding: '10px 20px',
                  cursor: loading ? 'not-allowed' : 'pointer',
                }}
              >
                Load More
              </Typography>
            )}
          </Stack>
        )}
      </List>
    </>
  );
};
