import { useState } from 'react';
import { DetailsAccordion } from '../StyledComponents';
import { DefectsTable } from './DefectsTable';

type Props = { assemblyId: string | undefined };

export const Defects = ({ assemblyId }: Props) => {
  const [defectsCount, setDefectsCount] = useState<number>(0);

  return (
    <DetailsAccordion title="Associated Defects" subTitle={defectsCount || ''}>
      <DefectsTable assemblyId={assemblyId} setDefectsCount={setDefectsCount} />
    </DetailsAccordion>
  );
};
