/* eslint-disable unicorn/prevent-abbreviations */
import { Button, Menu, MenuItem, SxProps, Typography } from '@mui/material';
import { useRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { theme } from '@/theme/theme';
import {
  useBulkLineFixingMutation,
  useRevertBulkLineFixingMutation,
} from '../PartsLabelReportButton';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { snackbarMessage } from '@/state';
import { RevertConfirmDialog } from './RevertConfirmDialog';
import { FixedAssembliesTable } from './FixedAssembliesTable';
import { LINE_FIXING_CSV_HEADERS } from '@/constants';
import { LineFixingErrorDialog } from './LineFixingErrorDialog';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as state from './state';
import { LineFixingValidationErrorTable } from './LineFixingValidationErrorTable';
import { generateFileDownload } from '@/utils/dom';
import * as taggingState from '@/state';
import { useGetLineFixingFileChangeHandler } from './hooks';
import { FixableTagsTable } from './FixableTagsTable';
type Props = {
  sx?: SxProps;
};

const downloadCsvTemplate = () => {
  const csvContent = LINE_FIXING_CSV_HEADERS.join(',');
  generateFileDownload(csvContent, 'line_fixing_template.csv');
};

export const LineFixingMenu = ({ sx }: Props) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [lineFixingState, setLineFixingState] = useRecoilState(state.lineFixingState);

  const allAssemblies = useRecoilValue(taggingState.allAssemblies);
  const isMenuOpen = Boolean(lineFixingState.menuAnchorElement);
  const [mutate, { loading: lineFixingLoading }] = useBulkLineFixingMutation();
  const [mutateRevert, { loading: revertLoading }] = useRevertBulkLineFixingMutation();
  const structureId = useGetStructureId();
  const { showSnackBar } = useSnackBarMessage({ atom: snackbarMessage });

  // Check if no assemblies were fixed via line fixing
  // This is determined if none of the platform's assemblies have the originalTagname field set
  const noAssembliesWereFixed = !allAssemblies.some((assembly) => assembly.originalTagname);

  // Check if no assemblies exist that were created by users and have no super category
  const noFixableTagsFound = !allAssemblies.some(
    (assembly) => assembly.isNewlyAdded && !assembly.superCategory
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLineFixingState({
      ...lineFixingState,
      menuAnchorElement: event.currentTarget,
      state: 'closed',
    });
  };

  const handleCloseMenu = () => {
    setLineFixingState({
      ...lineFixingState,
      menuAnchorElement: undefined,
      error: undefined,
    });
  };

  const handleRevert = () => {
    mutateRevert({
      variables: {
        input: { structureId },
      },
    })
      .then((data) => {
        const count = data.data?.revertBulkLineFixing?.updatedCount;

        if (!count || count === 0) {
          showSnackBar('No lines were reverted.', 'info');
          return;
        }

        const message = `${count} line(s) have been reverted. Please refresh the page to see the changes.`;
        showSnackBar(message, 'success');
      })
      .catch((error_) => {
        showSnackBar(error_.message, 'error');
      })
      .finally(() => {
        setLineFixingState({
          ...lineFixingState,
          state: 'closed',
        });
      });
  };

  const onFileChange = useGetLineFixingFileChangeHandler({
    handleCloseMenu,
    mutate,
    structureId,
  });

  const MENU_ITEMS: Array<{
    title: string;
    description: string;
    onClick?: () => void;
    disabled?: boolean;
  }> = [
    {
      title: 'Upload new file',
      description: 'Upload a new file for line fixing.',
      onClick: () => {
        fileRef.current?.click();
        handleCloseMenu();
      },
    },
    {
      title: 'View uploaded file',
      description: 'Click to view the uploaded file in Abyss Fabric.',
      onClick: () => {
        setLineFixingState({
          ...lineFixingState,
          state: 'table-modal-open',
        });
      },
      disabled: noAssembliesWereFixed,
    },
    {
      title: 'Fixable Tags',
      description: 'Tags created by users that have no super category.',
      onClick: () => {
        setLineFixingState({
          ...lineFixingState,
          state: 'fixable-tags-table-modal-open',
        });
      },
      disabled: noFixableTagsFound,
    },
    {
      title: 'Revert changes',
      description: 'Click to revert any recent changes.',
      onClick: () => {
        setLineFixingState({
          ...lineFixingState,
          state: 'confirm-revert',
        });
      },
    },

    {
      title: 'Download Template',
      description: 'CSV template including all the required columns.',
      onClick: downloadCsvTemplate,
    },
  ];

  return (
    <>
      <Button
        variant="contained"
        size="medium"
        color="primary"
        onClick={handleClick}
        sx={sx}
        endIcon={<ArrowDropDownIcon />}
        disabled={lineFixingLoading}
      >
        {lineFixingLoading ? 'Uploading' : 'Line Fixing File'}
      </Button>
      <Menu
        id="line-fixing-menu"
        anchorEl={lineFixingState.menuAnchorElement}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        sx={{
          mt: 2,
        }}
      >
        {MENU_ITEMS.map((item, index) => (
          <MenuItem
            disabled={item.disabled}
            key={index}
            onClick={item.onClick}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              my: 1,
            }}
          >
            <Typography color={theme.palette.primary.main}>{item.title}</Typography>
            <Typography color={theme.palette.secondary.dark}>{item.description}</Typography>
          </MenuItem>
        ))}
      </Menu>
      <input
        type="file"
        ref={fileRef}
        style={{ display: 'none' }}
        onChange={(event) => {
          onFileChange(event);
          fileRef.current!.value = '';
        }}
        accept=".csv"
      />
      <RevertConfirmDialog onConfirm={handleRevert} disabled={revertLoading} />
      <FixedAssembliesTable />
      <FixableTagsTable />
      <LineFixingErrorDialog />
      <LineFixingValidationErrorTable />
    </>
  );
};
