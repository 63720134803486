import { useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from '@/theme/theme';
import { LoadingButton } from '@/components/shared/LoadingButton';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import {
  AssemblyGoverningValueDocument,
  useNominateGoverningPointOfInterestMutation,
} from '@/__generated__/graphql';
import { GetOneAssemblyForDefectsDocument } from '@/__generated__/graphql';
import { GetAssembliesByTemplateFieldsFiltersForFilterSideBarDocument } from '@/__generated__/graphql';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { governingValueByAssemblyId, selectedAssemblyId } from '@/components/Analysis/state';

type NominateDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  defectClassId: string;
  poiName: string;
};

export const NominateDialog = ({
  isOpen,
  onClose,
  defectClassId,
  poiName,
}: NominateDialogProps) => {
  const setGoverningValue = useSetRecoilState(governingValueByAssemblyId);
  const assemblyId = useRecoilValue(selectedAssemblyId);
  const { showSnackBar } = useSnackBarMessage({ variant: 'filled' });

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const [nominateDefectClass, { loading }] = useNominateGoverningPointOfInterestMutation({
    variables: {
      input: {
        pointOfInterestId: defectClassId,
        lastModifiedFor: 'Governing defect class nomination',
      },
    },
    refetchQueries: [
      GetAssembliesByTemplateFieldsFiltersForFilterSideBarDocument,
      GetOneAssemblyForDefectsDocument,
      AssemblyGoverningValueDocument,
    ],
  });

  const handleNominateDefect = useCallback(() => {
    nominateDefectClass()
      .then((response) => {
        const newGoverningDefectClassId = response?.data?.nominateGoverningPointOfInterest;
        if (newGoverningDefectClassId) {
          showSnackBar(`The ${poiName} has been nominated successfully`, 'success');
        }
        if (!assemblyId) return;
        setGoverningValue((previous) => ({
          ...previous,
          [assemblyId]: { pointOfInterest: { id: defectClassId } },
        }));
      })
      .catch(() => showSnackBar('Failed to nominate defect', 'error'))
      .finally(() => onClose());
  }, [
    assemblyId,
    defectClassId,
    nominateDefectClass,
    onClose,
    poiName,
    setGoverningValue,
    showSnackBar,
  ]);

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth sx={{ radius: 2 }}>
      <DialogTitle>Nominate as governing defect?</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{ position: 'absolute', right: 8, top: 8, color: theme.palette.grey[500] }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ mt: 0, pt: 0, mb: 4 }}>
        <Typography variant="body2" fontWeight={400}>
          Are you sure you want to nominate {poiName} as governing defect?
        </Typography>
      </DialogContent>
      <Divider />
      <Divider orientation="horizontal" />
      <DialogActions>
        <Button onClick={handleClose} color="primary" size="large">
          Cancel
        </Button>
        <LoadingButton onClick={handleNominateDefect} loading={loading}>
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
