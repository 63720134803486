import { Box, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { EquipmentDetail, DetailLabel, DetailValue, StyledDivider } from './styles';
import { formatEnumForDisplay } from '@/utils';
import { GetAssemblyDetailsQuery, useScCategoryMappingQuery } from '@/__generated__/graphql';
import { EquipmentDetailRenderer } from './EquipmentDetailRenderer';
import { useFeatureFlag, useHavePermission } from '@/hooks';
import { Permissions } from '@/types';
import * as state from '@/components/Analysis/state';
import { localiseAreaMeasurement } from '@/utils/unitSystem';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { usePointOfInterestsCountLazyQuery } from '@/__generated__/graphql';
import { abyssColors } from '@/theme/colors';
import { NOT_APPLICABLE_LABEL } from '@/constants';
import { CriticalityLevel } from './CriticalityLevel';
import { PipeSpec } from './PipeSpec';
import { Viewpoints } from './AssociatedViewpoints/Viewpoints';
import { Blisters } from './AssociatedBlisters/Blisters';
import { ScCategory } from './ScCategory';
import { MediaUploads } from './MediaUploads/MediaUploads';
import { Defects } from './AssociatedDefects/Defects';
import { Detail } from './StyledComponents';

type Props = {
  assembly?: GetAssemblyDetailsQuery['assembly'];
  isLoadingAssemblyDetails?: boolean;
  isUncontextualized?: boolean;
};

const existingKeys = new Set([
  'risk_level_1_component',
  'risk_level_2_component',
  'risk_component_type',
  'risk_material_type',
  'risk_member_id',
  'risk_structure',
  'risk_equipment_tag',
]);

export const EquipmentDetails = ({
  assembly,
  isLoadingAssemblyDetails,
  isUncontextualized,
}: Props) => {
  const assemblyId = useRecoilValue(state.selectedAssemblyId);
  const isEsSheet = useRecoilValue(state.hasRiskAssessment);
  const structureId = useGetStructureId();
  const canReadDefects = useHavePermission(Permissions.READ_DEFECTS);
  const canAccessAitTools = useFeatureFlag(['ait-engineer', 'admin']);
  const unitSystem = useRecoilValue(state.unitSystem);

  const { data, loading: scCategoryLoading } = useScCategoryMappingQuery({
    variables: { structureId },
    skip: !structureId,
  });

  const scCategoryMappingExists = useMemo(() => {
    return Object.keys(data?.structure?.scCategoryMapping ?? {}).length > 0;
  }, [data?.structure?.scCategoryMapping]);

  const templateFieldData: {
    [key: string]: string;
  } = assembly?.templateFieldData;

  const [totalPoiCount, setTotalPoiCount] = useState(0);
  const [totalDefectPoisCount, setTotalDefectPoisCount] = useState(0);

  const [getPOICount, { loading: poiCountLoading }] = usePointOfInterestsCountLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const loading = useMemo(
    () => isLoadingAssemblyDetails || poiCountLoading || scCategoryLoading,
    [isLoadingAssemblyDetails, poiCountLoading, scCategoryLoading]
  );

  useEffect(() => {
    if (assemblyId && !isUncontextualized) {
      const fetchPoiCounts = async () => {
        const { data: totalPoiCountData } = await getPOICount({
          variables: {
            input: {
              structureId,
              assemblyIds: [assemblyId],
            },
          },
        });

        const { data: defectTypePoiCountData } = await getPOICount({
          variables: {
            input: {
              structureId,
              assemblyIds: [assemblyId],
              type: 'DEFECT_CLASS',
            },
          },
        });

        setTotalPoiCount(totalPoiCountData?.pointOfInterestsCount?.count ?? 0);
        setTotalDefectPoisCount(defectTypePoiCountData?.pointOfInterestsCount?.count ?? 0);
      };

      fetchPoiCounts();
    }
  }, [assemblyId, getPOICount, isUncontextualized, structureId]);

  const unContextualizedGeneralDetails = useMemo(() => {
    const fieldData = assembly?.templateFieldData || {};
    const fields = [
      { label: 'Level 1 Component', key: 'risk_level_1_component' },
      { label: 'Level 2 Component', key: 'risk_level_2_component' },
      { label: 'Component type', key: 'risk_component_type' },
      { label: 'Structure', key: 'risk_structure' },
      { label: 'Member Id', key: 'risk_member_id' },
      { label: 'Material type', key: 'risk_material_type' },
    ];

    return fields.map(({ label, key }) => ({
      label,
      value: fieldData[key] ?? NOT_APPLICABLE_LABEL,
    }));
  }, [assembly?.templateFieldData]);

  const componentTemplateData = useMemo(() => {
    const area = `${localiseAreaMeasurement(unitSystem, assembly?.area)}`;
    const { criticality, size, service } = templateFieldData || {};
    return [
      { title: 'Criticality', value: criticality },
      { title: 'Area', value: area },
      { title: 'Size', value: size },
      { title: 'Service Class', value: service },
      { title: 'POIs of Component', value: totalPoiCount },
      { title: 'Governing defect POIs', value: totalDefectPoisCount },
    ].filter(({ value }) => value !== undefined && value !== null);
  }, [assembly?.area, templateFieldData, totalDefectPoisCount, totalPoiCount, unitSystem]);

  if (loading) {
    return (
      <Box sx={{ p: 3, pt: 1 }}>
        {loading && (
          <Stack spacing={2} sx={{ py: 1 }} data-testid="pois-loader">
            {Array.from({ length: 6 }, (_, index) => (
              <Skeleton key={index} variant="rectangular" height={26} />
            ))}
          </Stack>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, pt: 1 }}>
      <Typography sx={{ color: abyssColors.primary[500] }} fontWeight={500} fontSize="18px">
        {assembly?.tagName}
      </Typography>
      {canReadDefects && <>{scCategoryMappingExists ? <ScCategory /> : <CriticalityLevel />}</>}

      <StyledDivider />

      {!isUncontextualized && (
        <>
          {componentTemplateData.map(({ title, value }, index) => (
            <Detail key={index} title={title} value={value} insideAccordion={false} />
          ))}

          {templateFieldData && canReadDefects && (
            <EquipmentDetailRenderer data={templateFieldData} area={assembly?.area ?? 0} />
          )}

          {canAccessAitTools && (
            <>
              <PipeSpec />
              <Blisters assemblyId={assemblyId} />
              <Defects assemblyId={assemblyId} />
            </>
          )}
          <Viewpoints assemblyId={assemblyId} />
          {assemblyId && (
            <MediaUploads
              referenceId={assemblyId}
              collectionName={'assembly'}
              files={assembly?.files || []}
            />
          )}
        </>
      )}

      {isUncontextualized && (
        <>
          <Box>
            {isEsSheet && (
              <Stack my="3.2rem" gap="1.6rem">
                {unContextualizedGeneralDetails.map((detail, index) => (
                  <EquipmentDetail key={detail.label + index}>
                    <Tooltip title={detail.label}>
                      <DetailLabel>{detail.label}</DetailLabel>
                    </Tooltip>
                    <Tooltip title={detail.value}>
                      <DetailValue>{detail.value}</DetailValue>
                    </Tooltip>
                  </EquipmentDetail>
                ))}
              </Stack>
            )}
            <StyledDivider />
            <Stack mt="3.2rem" gap="1.6rem">
              {Object.keys(assembly?.templateFieldData)
                .filter((key) => !existingKeys.has(key))
                .map((key, index) => (
                  <EquipmentDetail key={assembly?.templateFieldData[key] + index}>
                    <Tooltip title={formatEnumForDisplay(key).replace('Risk', '')}>
                      <DetailLabel>{formatEnumForDisplay(key).replace('Risk', '')}</DetailLabel>
                    </Tooltip>
                    <Tooltip title={assembly?.templateFieldData[key] || NOT_APPLICABLE_LABEL}>
                      <DetailValue>
                        {assembly?.templateFieldData[key] || NOT_APPLICABLE_LABEL}
                      </DetailValue>
                    </Tooltip>
                  </EquipmentDetail>
                ))}
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};
