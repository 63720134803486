import { abyssColors } from '@/theme/colors';
import { Stack, Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { typographyStyles } from './styles';

type Props = {
  title: string;
  value: string | number;
  my?: number;
  insideAccordion?: boolean;
};

const primaryColors = abyssColors.primary;

export const Detail = ({ title, value, my = 1.5, insideAccordion = true }: Props) => {
  const { titleStyles, valueStyles } = useMemo(
    () => ({
      titleStyles: {
        ...typographyStyles,
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: insideAccordion ? 400 : 500,
        color: primaryColors[insideAccordion ? 300 : 400],
      },
      valueStyles: {
        ...typographyStyles,
        minWidth: 80,
        textAlign: 'right',
        fontSize: '14px',
        color: primaryColors[500],
        fontWeight: insideAccordion ? 400 : 500,
      },
    }),
    [insideAccordion]
  );

  return (
    <Stack spacing={1} my={my} direction="row" alignItems="center" justifyContent="space-between">
      <Tooltip placement="top" title={title.length > 22 ? title : ''} arrow>
        <Typography sx={titleStyles}>{title}</Typography>
      </Tooltip>
      <Tooltip placement="top" title={value.toString().length > 15 ? value : ''} arrow>
        <Typography sx={valueStyles}>{value}</Typography>
      </Tooltip>
    </Stack>
  );
};
